import { useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { gql } from "apollo/types";
import { SecondaryButton } from "components/Button";
import GoogleIcon from "components/GoogleIcon";
import { Button } from "components/new/Button";
import DatePicker from "components/new/DatePicker";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from "components/new/Form";
import { FormInput } from "components/new/FormInput";
import { Input } from "components/new/Input";
import { LinkButton } from "components/new/LinkButton";
import { PasswordMeter } from "components/new/PasswordMeter";
import ROUTES from "const/routes";
import { t } from "i18n";
import React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useAuthDispatch } from "root/AuthProvider";
import { buildGoogleOAuth2URL, useSignOut } from "utils/auth";
import { authLocationState } from "utils/authLocationState";
import * as z from "zod";

const SIGN_UP = gql(`
mutation RegisterUser($email: String!, $password: String!, $church: String, $name: String!, $dateOfBirth: Date) {
  registerUser(email: $email, password: $password, church: $church, name: $name, dateOfBirth: $dateOfBirth) {
    accessToken
    refreshToken
  }
}
`);

const formSchema = z
  .object({
    email: z.string().email(t.validation.email.format),
    name: z.string().min(1, t.validation.email.required),
    church: z.string().optional(),
    dateOfBirth: z.date().optional(),
    password: z.string().min(8, t.validation.password.minLength),
    confirm: z.string().min(1, t.validation.confirmPassword.required),
  })
  .refine((data) => data.password === data.confirm, {
    message: t.validation.confirmPassword.match,
    path: ["confirm"],
  });

const SignUp: React.FC = () => {
  const setAuth = useAuthDispatch();
  const navigate = useNavigate();
  const signOut = useSignOut();
  const [signUp, { error, loading }] = useMutation(SIGN_UP);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: authLocationState.email ?? "",
      password: "",
      confirm: "",
      church: "",
      name: "",
      dateOfBirth: undefined,
    },
  });

  async function onSubmit({
    church,
    dateOfBirth,
    email,
    name,
    password,
  }: z.infer<typeof formSchema>) {
    await signOut();
    const { data } = await signUp({
      variables: {
        email,
        password,
        name,
        church,
        dateOfBirth,
      },
    });
    const tokens = data?.registerUser ?? null;
    if (tokens != null) {
      setAuth(tokens);
      navigate(ROUTES.HOME);
    }
  }

  function handleGoogleSignUp() {
    window.location.href = buildGoogleOAuth2URL({
      action: "signup",
      authLocationState,
    });
  }

  return (
    <div className="font-suisse flex w-screen max-w-[440px] flex-col gap-8 sm:p-6">
      <div className="flex flex-col gap-6 text-2xl">
        <Link to={ROUTES.HOME}>
          <img
            alt="landing"
            className="h-14 w-14 rounded-full"
            src="favicon.png"
          />
        </Link>
        <h1 className="font-bold">{t.screen.signUp.title}</h1>
        <h1 className="text-xl">{t.home.subtitle}</h1>
      </div>
      <Form
        className="flex flex-col gap-6"
        formContext={form}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="flex flex-col gap-5">
          <FormInput
            control={form.control}
            name="name"
            placeholder={t.screen.signUp.name.placeholder}
          />
          <FormInput
            control={form.control}
            name="email"
            placeholder={t.screen.signUp.email.placeholder}
          />
          <FormInput
            control={form.control}
            name="church"
            placeholder={t.screen.signUp.church.placeholder}
          />
          <FormField
            control={form.control}
            name="dateOfBirth"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormControl>
                  <DatePicker
                    error={!!fieldState.error}
                    name="dateOfBirth"
                    placeholder={t.screen.signUp.dob.placeholder}
                    value={field.value}
                    onChange={field.onChange}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormControl>
                  <>
                    <Input
                      error={!!fieldState.error}
                      id="password"
                      placeholder={t.screen.signUp.password.placeholder}
                      type="password"
                      {...field}
                    />
                    <PasswordMeter password={field.value} />
                  </>
                </FormControl>
                {!fieldState.error && (
                  <FormDescription>
                    {t.screen.signUp.password.hint}
                  </FormDescription>
                )}
                <FormMessage />
              </FormItem>
            )}
          />
          <FormInput
            control={form.control}
            name="confirm"
            placeholder={t.screen.signUp.confirm.placeholder}
            type="password"
          />
          {error && (
            <p className="text-sm font-medium text-new-danger60">
              {error.message}
            </p>
          )}
        </div>
        <div className="flex flex-col gap-4">
          <Button disabled={loading} type="submit">
            {t.screen.signUp.signUp}
          </Button>
          <SecondaryButton type="button" onClick={handleGoogleSignUp}>
            <GoogleIcon />
            <span className="ml-2 text-lg">
              {t.screen.signUp.signUpWithGoogle}
            </span>
          </SecondaryButton>
        </div>
      </Form>
      <div className="flex flex-col gap-5">
        <p>
          <span className="text-sm font-normal text-new-neutral60">
            {t.screen.signUp.alreadyHaveAccount}
          </span>
          <Link to={ROUTES.SIGN_IN}>
            <LinkButton>{t.screen.signUp.signInLink}</LinkButton>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default SignUp;
