import NavigationBar from "components/NavigationBar";
import ROUTES from "const/routes";
import { AuthRedirect } from "pages/AuthRedirect";
import GoogleRedirect from "pages/GoogleRedirect";
import Home from "pages/Home";
import ForgotPassword from "pages/new/ForgotPassword";
import ResetPassword from "pages/new/ResetPassword";
import SignIn from "pages/new/SignIn";
import SignUp from "pages/new/SignUp";
import React from "react";
import { Route, Routes } from "react-router-dom";

import { useAuth } from "./AuthProvider";
import { AuthRoutes } from "./AuthRoutes";
import { LayoutCentred } from "./LayoutCentred";

const AppRoutes = () => {
  const authenticated = useAuth() != null;

  return (
    <Routes>
      {!authenticated ? (
        // If user is not authenticated, we capture the path they wanted to navigate to, redirect them to signIn/signUp and back back to their initial path upon successful authentication
        <>
          {/* Catchall signIn redirect for routes that require authentication */}
          <Route element={<AuthRedirect />} path="*" />
        </>
      ) : (
        /* Captures all routes not specified explicitly here in <Routes> (route order does not matter for React Router) */
        <Route element={<AuthRoutes />} path="*" />
      )}

      <Route element={<NavigationBar withIcon={false} />}>
        <Route element={<Home />} key={ROUTES.HOME} path={ROUTES.HOME} />
      </Route>

      <Route element={<LayoutCentred />}>
        <Route element={<SignIn />} path={ROUTES.SIGN_IN} />
        <Route path={ROUTES.SIGN_UP}>
          <Route element={<SignUp />} index />
        </Route>
        <Route element={<ForgotPassword />} path={ROUTES.PASSWORD_FORGOTTEN} />
        <Route element={<ResetPassword />} path={ROUTES.PASSWORD_RESET} />
        <Route element={<GoogleRedirect />} path={ROUTES.GOOGLE_REDIRECT} />
      </Route>
    </Routes>
  );
};
export default React.memo(AppRoutes);
