import { useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { gql } from "apollo/types";
import { Button } from "components/new/Button";
import DatePicker from "components/new/DatePicker";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "components/new/Form";
import { FormInput } from "components/new/FormInput";
import { t } from "i18n";
import { useQuickToast } from "pages/Shared/hooks";
import React from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";

const UPDATE_PROFILE = gql(`
mutation UpdateProfileDetails($church: String, $name: String!, $dateOfBirth: Date) {
  updateProfileDetails(church: $church, name: $name, dateOfBirth: $dateOfBirth) {
    name
    church
    dateOfBirth
  }
}
`);

const formSchema = z.object({
  name: z.string().min(1, t.validation.email.required),
  church: z.string().optional(),
  dateOfBirth: z.date().optional(),
});

const Profile: React.FC<{ user: any }> = ({ user }) => {
  const { successToast } = useQuickToast();
  const [updateProfileDetails, { error, loading }] =
    useMutation(UPDATE_PROFILE);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      church: user?.church ?? undefined,
      name: user?.name,
      dateOfBirth: user?.dateOfBirth ? new Date(user?.dateOfBirth) : undefined,
    },
  });

  async function onSubmit({
    church,
    dateOfBirth,
    name,
  }: z.infer<typeof formSchema>) {
    updateProfileDetails({
      variables: {
        name,
        church,
        dateOfBirth,
      },
    }).then(() => {
      successToast({ description: t.screen.profile.success });
      window.location.reload();
    });
  }

  const isNameEmail = user.name.includes("@");

  return (
    <div className="flex w-full items-center justify-center">
      <div className="font-suisse mt-10 flex w-screen max-w-[440px] flex-col gap-8 sm:p-6">
        <div className="flex flex-col gap-6 text-2xl">
          <h1 className="font-bold">{t.screen.profile.title}</h1>
        </div>
        <Form
          className="flex flex-col gap-6"
          formContext={form}
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className="flex flex-col gap-1">
            <p className="font-md">{t.screen.signUp.name.placeholder}</p>
            {isNameEmail && (
              <p className="mb-[-6px] mt-1 text-sm font-medium text-new-danger60">
                {t.screen.signUp.name.emailWarning}
              </p>
            )}
            <FormInput
              className="mb-4"
              control={form.control}
              name="name"
              placeholder={t.screen.signUp.name.placeholder}
            />
            <p className="font-md">{t.screen.signUp.church.placeholder}</p>
            <FormInput
              className="mb-4"
              control={form.control}
              name="church"
              placeholder={t.screen.signUp.church.placeholder}
            />
            <p className="font-md">{t.screen.signUp.dob.placeholder}</p>
            <FormField
              control={form.control}
              name="dateOfBirth"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormControl>
                    <DatePicker
                      className="mt-2"
                      error={!!fieldState.error}
                      name="dateOfBirth"
                      placeholder={t.screen.signUp.dob.placeholder}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {error && (
              <p className="text-sm font-medium text-new-danger60">
                {error.message}
              </p>
            )}
          </div>
          <div className="flex flex-col gap-4">
            <Button disabled={loading} type="submit">
              {t.screen.profile.submit}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Profile;
