import { AwsRum } from "aws-rum-web";
import { PrimaryButton, SecondaryButton } from "components/Button";
import ROUTES from "const/routes";
import { t } from "i18n";
import React from "react";
import { Frown } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useSignOut } from "utils/auth";

type WithChildren = { children: React.ReactNode };

type FallbackProps = { resetError: () => void };

const ErrorDisplay: React.FC<FallbackProps> = ({ resetError }) => {
  const signOut = useSignOut();
  const navigate = useNavigate();
  function handleBackToLogin() {
    void signOut();
    resetError();
    navigate(ROUTES.SIGN_IN);
  }

  return (
    <div className="flex min-h-screen w-full items-center justify-center text-center">
      <div>
        <div className="sm:rounded:none my-6 rounded-lg border border-error100 bg-error50 shadow-md">
          <div className="flex flex-col items-center gap-4 p-12">
            <Frown size={40} />
            <div>
              <h2 className="mb-2 text-lg font-bold">
                {t.screen.errorBoundary.title}
              </h2>
              <p className="text-error600">{t.screen.errorBoundary.subtext}</p>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-4">
          <SecondaryButton className="flex-1" onClick={handleBackToLogin}>
            {t.screen.errorBoundary.back}
          </SecondaryButton>
          <PrimaryButton className="flex-1" onClick={() => resetError()}>
            {t.screen.errorBoundary.reload}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

interface ErrorBoundaryProps extends WithChildren {
  rumContext: AwsRum | null;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  { hasError: boolean }
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    if (this.props.rumContext) {
      this.props.rumContext.recordError(error);
    }
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorDisplay resetError={() => this.setState({ hasError: false })} />
      );
    } else {
      return this.props.children;
    }
  }
}

const AppErrorBoundary: React.FC<WithChildren> = ({ children }) => {
  return <ErrorBoundary rumContext={null}>{children}</ErrorBoundary>;
};

export default AppErrorBoundary;
