import { Button } from "components/new/Button";
import ROUTES from "const/routes";
import { useUserData } from "graphql/user";
import { t } from "i18n";
import { Link } from "react-router-dom";

const Header = () => {
  const user = useUserData();

  return (
    <div className="flex flex-col items-center">
      <img alt="landing" className="h-20 w-20 rounded-full" src="favicon.png" />
      <h1 className="mt-5 text-center text-header font-medium">
        {t.home.title}
      </h1>
      <h1 className="mt-5 text-center text-xl">{t.home.subtitle}</h1>
      <h1 className="mt-5 text-center text-xl font-medium">{t.home.church}</h1>
      {user?.name.includes("@") && (
        <a
          className="mt-5 text-center text-sm font-medium text-new-danger60"
          href="/profile"
        >
          {t.home.emailWarning}
        </a>
      )}
      {!user && (
        <Link to={ROUTES.SIGN_IN}>
          <Button className="mt-5">{t.common.signIn}</Button>
        </Link>
      )}
      <div className="mt-5 h-0.5 w-full bg-gray-100" />
    </div>
  );
};

export default Header;
