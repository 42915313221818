import React from "react";
import { Outlet } from "react-router-dom";

export const LayoutFullscreen: React.FC = () => {
  return (
    <main className="App-content mx-auto mb-8 w-full max-w-7xl overflow-y-auto">
      <Outlet />
    </main>
  );
};
