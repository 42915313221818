import { useMutation, useQuery } from "@apollo/client";
import { gql } from "apollo/types";

const QUIZ = gql(`
query Quiz($quizId: String!) {
  quiz(quizId: $quizId) {
    id
    title
    solved
    contest {
      id
      title
    }
    questions {
      choices {
        id
        text
      }
      text
      id
    }
  }
}
`);

const SUBMIT_QUIZ = gql(`
mutation SubmitQuiz($quizId: String!, $answers: [String!]!) {
  submitQuiz(quizId: $quizId, answers: $answers) {
    name
    choices {
      id
      text
    }
  }
}
`);

export const useQuiz = (quizId: string) => {
  const { data } = useQuery(QUIZ, {
    variables: { quizId },
  });

  return {
    data,
  };
};

export const useSubmitQuiz = () => {
  const [submitQuiz, { loading }] = useMutation(SUBMIT_QUIZ);

  return {
    submitQuiz,
    loading,
  };
};
