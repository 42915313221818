export const parameterize = (str: string) => {
  return str
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 -]/, "")
    .replace(/\s/g, "-");
};

export const coerceNumberString = (
  value: string | number | null,
): string | undefined => {
  return value?.toString().trim();
};
