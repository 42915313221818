import React from "react";

export const Box: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
}) => {
  return (
    <div
      className={`rounded-lg border border-gray200 bg-white shadow-md ${
        className ?? ""
      }`}
    >
      {children}
    </div>
  );
};
