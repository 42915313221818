import { useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { gql } from "apollo/types";
import { LoginUserMutation } from "apollo/types/graphql";
import GoogleIcon from "components/GoogleIcon";
import { Button } from "components/new/Button";
import { Form } from "components/new/Form";
import { FormInput } from "components/new/FormInput";
import { LinkButton } from "components/new/LinkButton";
import { SecondaryButton } from "components/new/SecondaryButton";
import ROUTES from "const/routes";
import { t } from "i18n";
import React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useAuthDispatch } from "root/AuthProvider";
import { buildGoogleOAuth2URL, useSignOut } from "utils/auth";
import {
  authLocationState,
  authLocationStateReset,
} from "utils/authLocationState";
import * as z from "zod";

const SIGN_IN = gql(`
mutation LoginUser($email: String!, $password: String!) {
  loginUser(email: $email, password: $password) {
    accessToken
    refreshToken
  }
}
`);

export type Tokens = LoginUserMutation["loginUser"];

const formSchema = z.object({
  email: z.string().email(t.validation.email.format),
  password: z.string().min(1, t.validation.password.required),
});

const SignIn: React.FC = () => {
  const route = authLocationState.from ?? ROUTES.HOME;
  const setAuth = useAuthDispatch();
  const navigate = useNavigate();
  const signOut = useSignOut();
  const [signIn, { error, loading }] = useMutation(SIGN_IN);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: authLocationState.email ?? "",
      password: "",
    },
  });

  const signInSuccess = (tokens: Tokens) => {
    // TODO(Jakub): add checkbox for selecting persistance mode
    setAuth(tokens);
    authLocationStateReset();
    navigate(route);
  };

  async function onSubmit(values: z.infer<typeof formSchema>) {
    // TODO(Jakub): an explicit signout would probably be better
    await signOut();
    const { data } = await signIn({ variables: values });
    const tokens = data?.loginUser ?? null;
    if (tokens) {
      signInSuccess(tokens);
    }
  }

  function handleGoogleSignIn() {
    window.location.href = buildGoogleOAuth2URL({
      action: "signin",
      authLocationState,
    });
  }

  return (
    <div className="font-suisse flex w-screen max-w-[440px] flex-col gap-8 sm:p-6">
      <div className="flex flex-col gap-6 text-2xl">
        <Link to={ROUTES.HOME}>
          <img
            alt="landing"
            className="h-14 w-14 rounded-full"
            src="favicon.png"
          />
        </Link>
        <h1 className="font-bold">{t.common.signIn}</h1>
        <h1 className="text-xl">{t.home.subtitle}</h1>
      </div>
      <Form
        className="flex flex-col gap-6"
        formContext={form}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="flex flex-col gap-5">
          <FormInput
            control={form.control}
            name="email"
            placeholder={t.screen.signIn.email.placeholder}
          />
          <FormInput
            control={form.control}
            name="password"
            placeholder={t.screen.signIn.password.placeholder}
            type="password"
          />
          {error && (
            <p className="text-sm font-medium text-new-danger60">
              {t.screen.signIn.error}
            </p>
          )}
        </div>
        <div className="flex flex-col gap-4">
          <Button disabled={loading} type="submit">
            {t.screen.signIn.signIn}
          </Button>
          <SecondaryButton type="button" onClick={handleGoogleSignIn}>
            <GoogleIcon />
            <span className="mr-2 text-lg">
              {t.screen.signIn.signInWithGoogle}
            </span>
          </SecondaryButton>
        </div>
      </Form>
      <div className="flex flex-col gap-5">
        <p>
          <span className="text-sm font-normal text-new-neutral60">
            {t.screen.signIn.donthaveAccount}
          </span>
          <Link to={ROUTES.SIGN_UP}>
            <LinkButton>{t.screen.signIn.signUpLink}</LinkButton>
          </Link>
        </p>
        <p>
          <Link to={ROUTES.PASSWORD_FORGOTTEN}>
            <LinkButton>{t.screen.signIn.forgotPassword}</LinkButton>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default SignIn;
