import React from "react";
import { cn } from "utils/tailwind-merge";

type FeaturedIconProps = {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
};

const FeaturedIcon: React.FC<FeaturedIconProps> = ({ children, className }) => {
  return (
    <div
      className={cn(
        "flex h-16 w-16 items-center justify-center rounded-full bg-new-neutral30 p-2",
        className,
      )}
    >
      {children}
    </div>
  );
};

export { FeaturedIcon };
