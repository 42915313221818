import { useQuery } from "@apollo/client";
import { gql } from "apollo/types";

const CONTEST = gql(`
query Contest($contestId: String!) {
  contest(contestId: $contestId) {
    contest {
      id
      title
      description
      imageUrl
      quizzes {
        id
        title
        description
        solved
        isPublished
      }
    }
    certificate
  }
}
`);

export const useContest = (contestId: string) => {
  const { data, refetch } = useQuery(CONTEST, {
    variables: { contestId },
  });

  return {
    data,
    refetch,
  };
};
