import { ContestParams } from "const/routes";
import { useParams } from "react-router-dom";

import { useContestAdmin } from "./hooks";

const Heading = () => {
  const { contestId } = useParams<ContestParams>() as ContestParams;

  const { data } = useContestAdmin(contestId);

  return (
    <div className="items-right flex w-full flex-col p-6">
      <h1 className="mt-5 text-right text-header">{data?.contest.title}</h1>
      <h1 className="mt-5 text-right text-xl">{data?.contest.description}</h1>
    </div>
  );
};

export default Heading;
