import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";
import { ArrowLeft, ArrowRight } from "react-feather";

import { cn } from "../../../utils/tailwind-merge";

const buttonVariants = cva(
  "inline-flex items-center justify-center rounded-lg font-suisse font-medium ring-offset-white transition-colors",
  {
    variants: {
      size: {
        default: "p-3 pt-2 pb-2 text-sm",
        lg: "p-4 pt-3 pb-3 text-md",
      },
      btnStyle: {
        normal:
          "bg-blue text-new-neutral10 text-xl font-bold hover:text-new-neutral40 focus:outline-new-neutral50",
        white: "bg-white text-black border w-12 border-black hover:bg-gray-200",
        transparent:
          "border border-black bg-transparent text-black hover:text-gray-400",
        disabled:
          "bg-new-neutral40 text-new-neutral10 hover:text-new-neutral10 focus:outline-none",
      },
    },
    defaultVariants: {
      size: "default",
      btnStyle: "normal",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  arrowDirection?: "forward" | "back";
  asChild?: boolean;
  backArrow?: boolean;
  disabled?: boolean;
  forwardArrow?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      arrowDirection,
      backArrow,
      btnStyle,
      children,
      className,
      disabled,
      forwardArrow,
      size,
      ...props
    },
    ref,
  ) => {
    const arrowClassName = cn(
      backArrow ? "mr-1" : "ml-1",
      size === "default" ? "w-4" : "w-5",
      disabled ? "text-new-neutral10" : "text-new-accent",
    );
    btnStyle = disabled ? "disabled" : btnStyle;

    return (
      <button
        className={cn(
          buttonVariants({ size, className, btnStyle }),
          "font-suisse",
        )}
        disabled={disabled}
        ref={ref}
        {...props}
      >
        {backArrow &&
          (!arrowDirection || arrowDirection === "back" ? (
            <ArrowLeft className={arrowClassName} />
          ) : (
            <ArrowRight className={arrowClassName} />
          ))}
        {children}
        {forwardArrow &&
          (!arrowDirection || arrowDirection === "forward" ? (
            <ArrowRight className={arrowClassName} />
          ) : (
            <ArrowLeft className={arrowClassName} />
          ))}
      </button>
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
