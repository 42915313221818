import { Translations } from "./types";

export const ar: Translations = {
  home: {
    title: "مسابقات الكتاب المقدس",
    subtitle:
      "خليك متصل بكلمة ربنا كل يوم لأنها حية وفعالة وأمضى من كل سيف ذي حدين",
    church: "كنيسة القديسين مارمرقس والبابا بطرس - الإسكندرية",
    emailWarning: "برجاء تعديل اسمك من هنا",
    currentContests: "المسابقات الحالية",
    pastContests: "المسابقات السابقة",
    upcomingContests: "المسابقات القادمة",
  },
  contest: {
    admin: {
      editQuiz: "تعديل",
    },
    user: {
      startQuiz: "ابدأ",
    },
  },
  quiz: {
    admin: {
      addQuestion: "اضافة سؤال",
      deleteChoice: "حذف الاختيار",
      deleteQuestion: "حذف السؤال",
      saveQuiz: "حفظ المسابقة",
      setCorrect: "تحديد الاجابة الصحيحة",
    },
    user: {
      submitQuiz: "تسجيل الاجابات",
      successSubmitQuiz: "تم حفظ المسابقة",
    },
  },
  common: {
    continue: "Continue",
    saveAndClose: "Save & close",
    Xaed: "{amount} AED",
    XaedPerMonth: "AED {amount}/mo",
    Xselected: "{count} Selected",
    Xyears: (count: number) => (count === 1 ? `1 year` : `${count} years`),
    acceptPayment: "Accept",
    accountNumber: "Account Number",
    action: "Action",
    activeEmployees: "Active",
    add: "Add",
    addAnother: "Add another",
    addSingle: "Add single employee",
    allTime: "All time",
    allCompanies: "All Companies",
    archived: "Archived",
    back: "Back",
    benefitPlans: "Benefit plans",
    benefits: "Benefits",
    bulkUpload: "Import CSV",
    areYouSure: "Are you sure?",
    cancel: "Cancel",
    clear: "Clear",
    clearAll: "Clear all",
    close: "Close",
    comingSoon: "Coming Soon",
    companies: "Companies",
    company: "Company",
    confirm: "Confirm",
    confirmPassword: "Confirm Password",
    copiedToClipboard: "Copied to clipboard",
    copy: "Copy",
    corruptedData: "Corrupted data",
    create: "Create",
    csvFiles: "CSV (max. 5 MB)",
    dashboard: "Dashboard",
    date: (d) =>
      d.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      }),
    delete: "Delete",
    developer: "Developer",
    documentFiles: "PDF, PNG, JPG or DOCS (max. 5 MB)",
    documents: "Documents",
    downloadAll: "Download all",
    download: "Download",
    dragAndDrop: "or drag and drop",
    edit: "Edit",
    email: "Email",
    emailAddress: "Email address",
    employee: "Employee",
    employeeInvitations: "Invitations",
    employees: "Employees",
    enrolled: "Enrolled",
    engineRequests: "Engine requests",
    enterEmail: "Enter email",
    error: "Error",
    errorGeneric: "Unexpected error occurred",
    ewa: "Salary Advance",
    fillRequired: "Please fill required fields.",
    filter: "Filter",
    filterCount: "Filter ({count})",
    filterX: (count) => (count ? `Filter (${count})` : "Filter"),
    filters: "Filters",
    first: "First",
    gotItThanks: "Got it, thanks",
    gratuity: "Gratuity",
    helpEmail: "",
    iUnderstand: "I understand",
    iban: "IBAN",
    imgFiles: "PNG or JPG (max. 5 MB)",
    inactive: "Inactive",
    investments: "Investments",
    inviteAdmins: "Invite admins",
    last: "Last",
    ltip: "LTIP",
    makePayment: "Make Payment",
    marketOrders: "Market Orders",
    matching: "Matching",
    maxFile:
      "File upload limit exceeded ({amount}); delete outdated files first",
    months12: "12 months",
    months6: "6 months",
    name: "Name",
    next: "Next",
    no: "No",
    noResult: "No results found",
    numberError: "Please enter number value.",
    optional: "(optional)",
    pageXOfY: "Page {pageIndex} of {pageCount}",
    past: "Past",
    phoneNumber: "Phone number",
    placeholder: "Type...",
    prev: "Prev",
    rejectPayment: "Reject",
    remove: "Remove",
    requests: "Requests",
    required: "{field} is required",
    removeInvitations: "Remove",
    resendInvitations: "Resend",
    role: "Role",
    saveChanges: "Save Changes",
    scheme: "Scheme",
    schemes: "Schemes",
    search: "Search",
    select: "Select",
    selectDate: "Select date",
    selectDates: "Select dates",
    send: "Send",
    sendInvite: "Send invite",
    showX: "Show {count}",
    signIn: "تسجيل الدخول",
    signOut: "Sign out",
    signUp: "Get started",
    skip: "Skip",
    status: "Status",
    submitPayment: "Submit for payment",
    success: "تم بنجاح",
    tagline: "Bible Quiz",
    tooManyFiles: "Exceeded file limit ({amount})",
    payments: "Payments",
    undo: "Undo",
    upTo: "upto",
    upload: "Click to upload",
    users: "Users",
    validEmail: "Please enter valid email.",
    viewCompanies: "View companies",
    yes: "Yes",
    ytd: "YTD",
    fileInvalidType: "Invalid file type",
    fileTooLarge: "File too large",
    fileTooSmall: "File too small",
  },
  navBar: {
    personalSettings: "Personal settings",
    helpRequest: "Help",
  },
  products: {
    gratuity: "Gratuity",
    incentive: "Incentive",
    savings: "Savings",
  },
  screen: {
    acceptEmployeeInvitation: {
      description: (email) => `Confirmed as ${email}`,
      heading: "Welcome to Aurem",
      signOut: "Sign out",
      admin: {
        left: "You can also ",
        link: "access the Company Admin Dashboard",
      },
      desktop: {
        heading: "Scan to download the app",
        description:
          "Download the Aurem app today and start taking control of your financial future!",
        howto: [
          "Open the camera or QR scanner app on your phone",
          "Point the camera on the QR code",
          "Click on the link that shows up to download the app",
        ],
      },
      mobile: {
        heading: "Download the app",
        description:
          "Download the Aurem app today and start taking control of your financial future!",
        appStore: "Download on the App Store",
        googlePlay: "Get it on Google Play",
      },
    },
    acceptAdminInvitation: {
      accept: "Accept",
      description: (companyName: string) =>
        `Do you wish to join ${companyName} and start managing it on Aurem?`,
      error:
        "Make sure you're using the right link or contact your company administrator",
      heading: "Accept Invitation",
      reject: "Reject",
    },
    errorBoundary: {
      title: "Oh Snap!",
      subtext: "Unexpected Error Ocurred",
      back: "Back To Login",
      reload: "Try Again",
    },
    errorPage: {
      title: "404",
      subtext: "Page not found",
      back: "Back",
    },
    unauthorised: {
      title: "Unauthorised",
      subtext: "This user does not have permissions to access the application",
      back: "Back to sign in",
    },
    signIn: {
      donthaveAccount: "عضو جديد؟ ",
      signIn: "تسجيل الدخول",
      signInWithGoogle: "تسجيل الدخول بجوجل",
      signUpLink: "اضغط هنا",
      forgotPassword: "نسيت كلمة المرور؟",
      error: "البريد الالكتروني او كلمة المرور غير صحيحة",
      email: {
        label: "Email",
        placeholder: " البريد الالكتروني*",
        invalid: "البريد الالكتروني غير صحيح",
      },
      password: {
        label: "Password",
        placeholder: "كلمة المرور*",
      },
      title: "Log in to Aurem",
      youCanAlso: "You can also",
      continueWithSaml: "Continue with SAML SSO",
      mfa: {
        heading: "Confirm that it’s you",
        description: "Open your authenticator app on your mobile device.",
        label: "Enter the generated code",
        placeholder: "Six digit code",
      },
    },
    signInWithSaml: {
      continueWithEmail: "Continue with email",
      signIn: "Sign in with SAML",
    },
    profile: {
      submit: "حفظ",
      success: "تم حفظ التغييرات",
      title: "الحساب",
    },
    signUp: {
      signUp: "انشاء حساب",
      signUpWithGoogle: "انشاء حساب بجوجل",
      alreadyHaveAccount: "لديك حساب؟ ",
      signInLink: "تسجيل الدخول",
      name: {
        emailWarning: "الاسم لا يمكن ان يكون بريد الكتروني",
        placeholder: "الاسم الثلاثي*",
      },
      email: {
        label: "البريد الالكتروني*",
        placeholder: "البريد الالكتروني*",
      },
      church: {
        placeholder: "الكنيسة (اختياري)",
      },
      dob: {
        placeholder: "تاريخ الميلاد (اختياري)",
      },
      password: {
        hint: "كلمة المرور يجب ان تكون 8 احرف على الاقل",
        label: "كلمة المرور*",
        placeholder: "كلمة المرور*",
      },
      confirm: {
        label: "تأكيد كلمة المرور*",
        placeholder: "اعد كتابة كلمة المرور*",
      },
      disclaimer: {
        link: "terms of use.",
        text: "By continuing to use this platform, you are agreeing to Aurem’s ",
      },
      title: "قم بانشاء حساب",
    },
    signUpSuccess: {
      continue: "Continue to set up",
      error: {
        cta: "Go to sign up",
        unregistered: "You have not registered yet!",
      },
      invitation: {
        description: (email) =>
          `Your email ${email} has been verified and invitation accepted. Let's get you set up now.`,
        heading: "Let's get you set up",
      },
      title: "Account created",
      verification: {
        description: (email) =>
          `We have sent a verification link to your email ${email}`,
        heading: "Check your email",
      },
    },
    verifyEmail: {
      resendVerificationEmail: "Resend verification email",
      verifying: "Verifying email address...",
    },
    ewa: {
      configuration: {
        minimumSalary: {
          description:
            "Minimum salary for employees who are eligible to request salary advance",
          title: "Minimum Salary",
          min: "Minimum Salary should be a positive number.",
        },
        payCycle: {
          description: "Specify the criteria of the pay cycle",
          endDate: "End day",
          maxEndDate: "End day should be less than 28",
          maxStartDate: "Start day should be less than 28",
          minEndDate: "Start day should be greater than 1",
          minStartDate: "End day should be greater than 1",
          startDate: "Start day",
          title: "Pay cycle",
        },
        redeemableAmount: {
          description:
            "Percentage of earned wage that is redeemable. Should be between 33% - 50%",
          max: "Reedemable amount should be less than 50",
          min: "Reedemable amount should be greater than 33",
          title: "Redeemable Amount",
        },
        saveButton: "Save",
        saveModal: {
          confirmButton: "Changes saved",
          description: "Changes saved successfully",
          title: "Got it, thanks",
        },
      },
      employees: {
        allEmployeesAssigned:
          "There are no employees that can be assigned to Salary Advance.",
        assignEmployeesModal: {
          confirmButton: "Got it, thanks",
          description:
            "You have successfully assigned employees to Salary Advance.",
          title: "You’re all set up!",
        },
        eid: "EID Number",
        employmentStartDate: "Employed Since",
        fullSalary: "Full Salary",
        gridEmpty: "No assigned employees",
        jobTitle: "Job Title",
        name: "Employee Name",
        removeEmployeeButton: "Remove Employees",
        removeEmployeeModal: {
          cancelButton: "Cancel",
          confirmButton: "Remove",
          title:
            "Are you sure you want to remove these employees from Salary Advance?",
        },
        workEmail: "Work Email",
      },
    },
    forgotPassword: {
      cancel: "الغاء",
      description:
        "ادخل البريد الالكتروني الخاص بك وسوف نرسل لك رابط لاعادة تعيين كلمة المرور",
      email: {
        label: "البريد الالكتروني*",
        placeholder: "ادخل البريد الالكتروني",
      },
      error:
        "The link could not be sent. Check your internet connection and try again.",
      submit: "ارسال",
      title: "نسيت كلمة المرور",
      needHelp: "",
      success: {
        title: "تم ارسال الرابط",
        description:
          "تم ارسال الرابط لاعادة تعيين كلمة المرور الى بريدك الالكتروني",
      },
    },
    emailVerification: {
      title: "Check your email",
      text: "We sent a verification code to your email.",
      verificationCode: "Enter verification code",
      verificationFailed:
        "The email verification failed, please try resending the email.",
      verificationResent: "A new verification link has been sent to your email",
      verificationSucceeded: "The email verification is successful.",
      code: "Six digit code",
      verify: "Verify",
      error: "Invalid verification code",
      question: "Didn't receive the email?",
      resend: "Click to resend",
      back: "Back to login",
      verificationEmailFailed:
        "The re-sending of email verification has failed.",
    },
    passwordSetup: {
      passwordSetup: "Password setup",
      heading: "Set New Password",
      info: "Password must be 8 or more characters.",
      enterPassword: {
        required: "Password is required",
        label: "Enter your password",
      },
      confirmPassword: {
        required: "Confirm password is required",
        label: "Confirm your password",
        match: "Passwords must match",
      },
    },
    invitationAcceptance: {
      accepting: "Accepting the invitation...",
      adminFailure:
        "The company admin verification failed, contact your inviter to request a new invitation",
      adminSuccess: "You are now Company Admin!",
      employeeFailure:
        "We could not add you to this company. Contact your employer to request a new invitation.",
      employeeSuccess: "You are now an employee of this company. Welcome!",
      invited: "The admin is invited to the company.",
    },
    notFound: {
      back: "Go back",
      companies: "Go to view companies",
      dashboard: "Go to dashboard",
      description:
        "Sorry, the page you're looking for doesn't exist or has been moved.",
      error: "404 error",
      heading: "Page not found...",
    },
    registerAdmin: {
      title: "Create new account",
    },
    fundFactSheet: {
      assetAllocation: "Asset allocation",
      byCategory: "By Category",
      bySector: "By Sector",
      company: "Company",
      data: "Data",
      duration: "Duration",
      title: "Fund information",
      subtitle: "A quick fact sheet of the fund",
      performance: "Performance",
      volatility: "Volatility",
      fee: "Fee",
      graph: "Graph",
      fund: "Fund",
      dateOfInception: "Date of inception",
      benchmark: "Benchmark",
      sector: "Sector",
      top10: "Top 10",
      performanceAgainstBenchmark: "Performance against benchmark",
      performanceAbbreviations: {
        mtd: "MTD",
        ytd: "YTD",
        threem: "3M",
        sixm: "6M",
        oney: "1Y",
      },
    },
    home: {
      alreadyPartOfACompany: "Already part of a company!",
      youGotAccess: "You’ve got access to the following companies",
      confirmedX: "Confirmed as {email}",
      title: "Create a new Aurem \n company",
      customize: "Customize benefits for your organisation",
      invite: "Invite employees",
      unlock: "Unlock insights",
      employee: {
        title: "Take control of your finances with Aurem",
        description:
          "Our user-friendly app makes it easy for you to track your investments and returns, set goals, and adjust your contributions. Download the Aurem app today and start taking control of your financial future!",
        scan: "Scan to download the app",
        steps: [
          "Open your camera on your phone",
          "Point the camera on the QR code and focus",
          "Click on the link that shows up to download the app",
        ],
      },
    },
    resetPassword: {
      title: "انشاء كلمة مرور جديدة",
      password: {
        label: "كلمة المرور الجديدة*",
        placeholder: "ادخل كلمة المرور الجديدة",
      },
      confirm: {
        label: "تأكيد كلمة المرور*",
        placeholder: "اعد كتابة كلمة المرور الجديدة",
      },
      submit: "حفظ كلمة المرور",
      cancel: "الرجوع الى تسجيل الدخول",
      passwordHint: "كلمة المرور يجب ان تكون 8 احرف على الاقل",
    },
    dashboard: {
      welcome: "Welcome",
      welcomeX: "Welcome, {userName}",
      onboarding: {
        title: "Let’s get you set up!",
        benefits: "Set up Benefits",
        paragraph:
          "Take the first step towards building your employees’ financial wellbeing",
        verify: "Verify Company",
        employees: "Upload Employees",
        admins: "Invite more admins (optional)",
      },
      upcomingPayment: {
        title: "You’ve got an upcoming payment",
        sublineX: "Payment for {date}",
        XcardHeader: "{date} contribution payment",
        cardTextInfoX: "{amount} AED",
        cardTextDueX: "Due by {date}",
        primaryBtnText: "Make Payment",
        secondaryBtnText: "View details",
      },
      contributions: {
        title: "Contributions",
        subline: "All insights and summary into your contributions",
        emptyTitle: "No Contributions Yet",
        emptySubline: "It doesn’t look like you have any contributions yet.",
        emptyButton: "Upload employees",
        emptyChartTitle: "You don’t have any payments yet",
        emptyChartSubline:
          "We’ll let you know when payments start to appear here and you need to make a payment.",
        cards: {
          employees: "Enrolled employees",
          benefits: "Total benefits paid",
          contribution: "Avg employee contribution",
        },
      },
      gratuityLiability: {
        emptyButton: "Set up Gratuity",
        emptySubline:
          "The amount of gratuity liability depends on the number of employees, their salaries and start dates. Set up a gratuity plan to see your liability here.",
        emptyTitle: "No gratuity liability yet.",
        subline: "An aggregate of all the employees gratuity accrued",
        title: "Gratuity Liability",
        funded: "Funded",
        notFunded: "Not Funded",
        requestToFundGratuity: "Request to fund gratuity",
        total: "Total",
      },
      investments: {
        title: "Investments",
        subline: "See how your investments are performing",
        emptyTitle: "No Investments Yet",
        emptySubline: "It doesn’t look like you have any investments yet.",
        emptyButton: "Set Up Schemes",
      },
      stats: {
        enrolled: "Enrolled Employees",
        benefits: "Total Benefits paid",
        retention: "Retention Rate",
      },
      ewa: {
        currentMonth: "Current month",
        employeesNumber: "Employees requesting Salary Advance",
        last3Months: "Last 3 months",
        previousMonth: "Previous month",
        subtitle: "Snapshot of salary advance service",
        totalPayouts: "Total payouts",
      },
      actionItems: {
        ewa: {
          heading: "Salary advance requests",
          item: (name: string, amount: number) =>
            `${name} requested ${amount} AED`,
          subtitle: "Pending requests from employees",
        },
        heading: "Actions items",
        subtitle: "Tasks that require your attention",
      },
      actions: {
        tasks: "Action tasks",
        thisMonthsPayment: "This months payment",
        dueByX: "Due by {date}",
        viewDetail: "View details",
      },
    },
    requests: {
      grid: {
        name: "Company name",
        submittedOn: "Submitted on",
        status: "Status",
        emptyPrompt: "No requests to view.",
      },
    },
    users: {
      grid: {
        isVerified: "is Verified",
        roles: "Roles",
        employments: "Employments",
      },
    },
    developer: {
      executeProducts: "Execute products",
      executeProductsDescription:
        "Create Engine requests from products (LTIP, Gratuity, Matching). Please make sure the current day of the month matches benefit.executedAt or 1st day of the month.",
      processBuyTransactions: "Process Buy transactions",
      processBuyTransactionsDescription:
        "Create Market orders from Transfer type transactions.",
      processSellTransactions: "Process Sell transactions",
      processSellTransactionsDescription:
        "Create Market orders from Transfer type transactions.",
      processSwitchTransactions: "Process Switch transactions",
      processSwitchTransactionsDescription:
        "Create Market orders from Transfer type transactions.",
      executeFees: "Execute Fees",
      executeFeesDescription: "Generate Fees for each company.",
      processRequests: "Process requests",
      processRequestsDescription:
        "Process open requests so that payment/transfer transactions could be created.",
      reportUsages: "Report Usages",
      reportUsagesDescription:
        "Report daily active employees usage to Stripe for each active subscription",
    },
    companies: {
      grid: {
        name: "Company name",
        email: "Email",
        registeredNumber: "Registered Number",
        createdOn: "Created on",
        address: "Address",
        telephone: "Contact Phone",
        website: "Website",
        emptyPrompt:
          "Aurem makes it easy to enable the future of workplace savings by letting you add all of your \n employees to the platform in a single click.",
      },
      addCompany: "Add new company",
    },
    companyDetails: {
      emailHR: "Email HR",
      requestMoreInfo: "Request more info",
      reject: "Reject",
      approve: "Approve",
    },
    companyCreate: {
      companyApproved: "Company approved",
      companyAdded: "Company added",
      title: "Create company",
      description: "All fields are required for trustee's approval",
      submit: "Create and continue",
      companyInfo: "Company Info",
      legalForm: {
        error: "Legal form is required",
        label: "Legal form",
      },
      dateOfIncorporation: {
        error: "Date of Incorporation is required",
        label: "Date of Incorporation",
      },
      mainContact: "Main Contact",
      name: {
        label: "Company Name",
        error: "Company Name is required",
      },
      registeredNumber: {
        label: "Trade license number",
        error: "Trade license number is required",
      },
      registeredAddress: {
        label: "Registered Address",
        error: "Registered Address is required",
      },
      address: {
        label: "Address",
        error: "Address is required",
      },
      poBox: {
        label: "P.O Box",
      },
      emirate: {
        label: "Emirate",
        error: "Emirate is required",
      },
      website: {
        label: "Website",
        error: "Website is required",
      },
      telephone: {
        error: "Phone number is required",
      },
      email: {
        label: "Admin email",
        error: "Company Email is required",
      },
      bankingDetails: "Company Banking details",
      bankName: {
        error: "Bank",
        label: "Bank",
      },
      bankCountry: {
        error: "Bank country",
        label: "Bank country",
      },
      payeeAccountName: {
        error: "Payee Account Name",
        label: "Payee Account Name",
      },
      payeeSWIFT: {
        error: "Payee SWIFT/BIC",
        label: "Payee SWIFT/BIC",
      },
      iban: {
        error: "IBAN",
        label: "IBAN",
      },
    },
    companyUpdate: {
      submit: "Update Company Data",
      title: "Update Company",
      companyUpdated: "Company Data Updated",
    },
    tradingDetails: {
      bankLocation: {
        label: "Bank Country",
        required: "Bank location is required",
      },
      bankName: { label: "Bank", required: "Bank name is required" },
      iban: { label: "IBAN", required: "IBAN is required" },
      submit: "Update trading details",
      reset: "Reset Changes",
      title: "Trading Banking details",
      success: "Trading Banking details updated",
      description:
        "Fill in with details of the trustee assigned account you’ll be trading from.",
    },
    companyUpload: {
      title: "Upload KYC Documents",
      question: "Ready to submit?",
      info: "Once submitted, your documents and details will be reviewed by the trustee.",
      anotherinfo: "Approvals usually take 2-3 days.",
      submit: "Submit for approval",
      documentsUploaded: "Documents uploaded",
    },
    companyDocuments: {
      submit: "Update Documents",
    },
    employees: {
      headline: "Employees",
      form: {
        personal: {
          heading: "Personal info",
          subheading: "Let's start with the basics",
        },
        contact: {
          heading: "Contact",
          subheading: "A way to get in touch with them",
        },
        details: {
          heading: "Employment details",
          subheading: "For things to run smoothly",
        },
        firstName: {
          label: "First name",
          required: "First name is required",
          hint: "Must match their government ID",
          placeholder: "First name",
        },
        lastName: {
          label: "Last name",
          required: "Last name is required",
          hint: "Includes all middle and last names",
          placeholder: "Last name",
        },
        emiratesId: {
          label: "Emirates ID",
          required: "Emirates ID is required",
          placeholder: "xxx-xxxx-xxxxxxx-x",
        },
        labourId: {
          label: "Ministry of Labour ID",
          error: "Ministry of Labour ID must be number",
          hint: "This is the 14 digit personal number on the employees Ministry of Labour ID",
          placeholder: "xxxxxxxxxxxxxx",
        },
        nationality: {
          label: "Nationality",
          placeholder: "Select the nationality",
          required: "Nationality is required",
        },
        email: {
          label: "Work email",
          hint: "This is the email they will get an invite on and will use to access the app",
          placeholder: "Work email",
        },
        jobTitle: {
          label: "Job title",
          required: "Job title is required",
          hint: "This makes it easier for you to sort and filter employees later.",
          placeholder: "Job title",
        },
        iban: {
          label: "IBAN",
          hint: "This is their salary account any payments will be made out to",
          placeholder: "AE07 0331 2345 6789 0123 456",
        },
        startDate: {
          label: "Start of employment",
          required: "Start of employment is required",
          hint: "To calculate their gratuity entitlement",
        },
        endDate: {
          label: "End of employment",
          hint: "We use this to initiate an off boarding process",
        },
        basicSalary: {
          label: "Monthly basic salary",
          required: "Basic salary is required",
          error: "Basic salary must be a number",
          hint: "The base salary on which gratuity is calculated",
          placeholder: "Monthly basic salary in AED",
        },
        fullSalary: {
          label: "Monthly salary",
          required: "Monthly salary is required",
          error: "Salary must be a number",
          hint: "The total remuneration at the end of the month including allowances",
          placeholder: "Monthly salary in AED",
        },
        employmentStatus: {
          placeholder: "Select the employment status",
          label: "Employment status",
          required: "Employment status is required",
        },
        gender: {
          placeholder: "Select the gender",
          label: "Gender",
          required: "Gender is required",
        },
        workPermitNumber: {
          label: "Work permit number (optional)",
          hint: "",
          placeholder: "xxxxxxxx",
        },
        workPermitExpiryDate: {
          label: "Work permit number date (optional)",
          hint: "",
        },
        dateOfBirth: {
          label: "Date of Birth",
          hint: "",
        },
        employeeIdNumber: {
          placeholder: "Employee ID number",
          label: "Employee ID number (optional)",
          hint: "",
        },
        department: {
          placeholder: "Department",
          label: "Department (optional)",
          hint: "",
        },
        branch: {
          placeholder: "City/Branch office",
          label: "City/Branch office (optional)",
          hint: "",
        },
      },
      addEmployees: "Add employees",
      stats: {
        avgContribution: "Avg. employee contribution",
        ltipVesting: "Total LTIP in vesting",
        retention: "Retention Rate",
      },
      addEmployee: {
        title: "New employee",
        success: "Successfully created the employee",
        error: "Failed to create employee",
        addEmployee: "Add an employee",
        general: "General",
        personalData: "Personal data",
        emiratesId: "Emirates ID",
        workEmail: "Work email",
        personalEmail: "Personal email",
        firstName: "First name",
        lastName: "Last name",
        employmentStart: "Start of employment",
        salary: "Salary (Monthly)",
        basicSalary: "Basic salary (Monthly)",
        fullSalary: "Full salary (Monthly)",
        iban: "IBAN",
        jobTitle: "Job title",
        label: "Add an employee",
        dateOfBirth: "Date of Birth",
        nationality: "Nationality",
        address: "Address",
        gender: "Gender",
        country: "Country residence",
        update: "Update employee",
        create: "Add an employee",
        endDate: "End of employment (optional)",
        phoneLengthError: "Phone number must be at most 14 characters",
        phoneFormatError: "Phone number must be in format +1XXXXXXXXXXXX",
        labourId: "Ministry of Labour ID (optional)",
      },
      importCSV: "Bulk upload",
      grid: {
        name: "Employee Name",
        benefitsCount: "Benefits Count",
        employedSince: "Employed Since",
        jobTitle: "Job Title",
        basicSalary: "Basic Salary",
        enrolled: "Enrolled On",
        updated: "Last Updated",
        email: "Work Email",
        emiratesId: "EID Number",
        date: "Date of Birth",
        group: "Group",
        contribution: "Contribution",
        ltipEarned: "LTIP Earned",
        ltipVesting: "LTIP Vesting",
        emptyInactivePrompt: "There are no inactive employees.",
        emptyPrompt:
          "Aurem makes it easy to enable the future of workplace savings by letting you add \n all of your employees to the platform in a single click.",
        servicePeriod: "Service period",
        companyAmount: "Company amount",
        salaryAmount: "Salary amount",
        invitationEmail: "Invitation email",
        fullSalary: "Full salary",
      },
      bulkUpload: {
        title: "Employee Import",
        description:
          "Aurem makes it easy to enable the future of workplace savings by letting you bulk manage all your employees.",
        upload: "Click to upload",
        dragAndDrop: "or drag and drop",
        dropExtensions: "CSV (max. 5 MB)",
        size: "MB",
        import: "Import employees",
        error: "Please fix the error fields once you complete the upload",
        errorTitle: "Missing fields",
        employeesUploaded: "Employees uploaded",
        downloadTemplate: "Download template",
      },
      filters: {
        department: "Department",
        level: "Level",
        startDate: "Start of employment date",
        enrolled: "Enrolled on date",
        group: "Group",
        withBenefits: "Part of a benefit plan",
        lastMonth: "Last month",
        last3Months: "Last 3 months",
        last6Months: "Last 6 months",
        thisYear: "This year",
        salaryRange: "Salary Range",
        lastYear: "Last year",
      },
      editEmployee: {
        success: "Successfully edited the employee",
        error: "Failed to edit employee",
        account: "Account",
        employmentDetails: "Employment details",
        editedHeading: "Changes saved",
        editedSubtitle: "Changes to the employee have now been saved",
        savings: "Savings",
        gratuity: "Gratuity",
        employeeId: "Employee ID",
        inVesting: "In vesting",
        contribution: "Employer contribution",
        earnedGratuity: "Earned gratuity",
        enrolled: "Enrolled on",
        group: "Group",
      },
      delete: {
        button: "Delete",
        description: "Are you sure you want to delete this employee?",
        title: "Delete employee",
      },
      inviteEmployee: {
        invite: "Invite employee",
        button: "Send Invitation",
        title: "Invite employees",
        description: "Invite employees to Aurem",
        invited: "Employee invited",
      },
      resentInvitations: "The selected invitation(s) have been resent.",
      destroyedInvitations: "The selected invitation(s) have been destroyed.",
    },
    scheme: {
      schemeSettings: "Scheme settings",
      addEmployee: "Assign Employees",
      changeGroup: {
        title: "Change group",
        selected: "{count} selected",
        removedFromX: "Removed from group {groupName}",
        chnagedToX: "Changed to group {groupName}",
      },
      schemeUpdated: "Scheme updated",
      grid: {
        employeeName: "Employee Name",
        groups: "Groups",
        employeeContribution: "Employee contribution",
        companyContribution: "Company contribution",
        earned: "Earned",
        vesting: "In vesting",
      },
      gratuity: {
        startDate: "Start Date",
        contribution: "Contribution",
        basicSalary: "Basic Salary",
      },
      description: "Retain key personnel through matching investment plans",
      startDate: {
        label: "Scheme start date",
        description:
          "This is the start date of your enrollment duties and contributions",
      },
      defaultFund: {
        label: "Default fund",
        description: "Choose from our world-class funds",
      },
      groups: {
        label: "Groups",
        button: {
          add: "Add Group",
          delete: "Delete Group",
          new: "New Group",
        },
        edit: "Edit Group",
        assignEmployees: "Assign employees",
        description: "Use groups to manage contribution rates at your company",
      },
      group: {
        name: {
          placeholder: "Group Name",
          label: "Group Name",
        },
        contribution: {
          label: "Contribution Amount",
          title: "Contribution",
        },
        vesting: {
          placeholder: "Vesting",
          label: "Vesting",
        },
        contributionBase: {
          tooltip: "What the company's contribution is based on",
          basic: "Basic Salary",
          full: "Full Salary",
          title: "Base Contribution on",
        },
        contributionType: {
          tooltip: "How the contribution is calculated",
          percentage: "Percentage",
          absolute: "Absolute value in AED",
          title: "Contribution Type",
        },
      },
      fullSalary: "Full Salary?",
      addToGroup: {
        title: (count) => `Add ${count} employee${count === 1 ? "s" : ""}`,
        titleDefault: (count) =>
          `Add ${count} employee${count === 1 ? "s" : ""} to Default group`,
        group: {
          label: "Group",
        },
        employee: {
          label: "Employee name",
        },
        addedToX: "Added to group {groupName}",
        addedTo: "Added to group.",
      },
    },
    engineRequests: {
      grid: {
        type: "Type",
        state: "State",
        createdOn: "Created On",
        origin: "Origin",
      },
      status: {
        processing: "Processing",
        close: "Close",
        open: "Open",
        error: "Error",
      },
    },
    investments: {
      grid: {
        title: "Investments",
        units: "Units",
        switchId: "Switch ID",
        description:
          "Track fund allocation and redeption instructions and their status",
        fundId: "Fund ID",
        type: "Type",
        amount: "Amount",
        actions: "Actions",
        submittedOn: "Submitted On",
        transferBy: "Transfer by",
      },
      invest: "Invest",
      confirm: {
        unitsAmount: {
          labelBuy: "Units Bought",
          labelSell: "Units Sold",
          match:
            "This number does does not match the specified unit price. Please double check both values.",
          required: "Units are mandatory",
        },
        cancel: "Cancel",
        description:
          "Please, confirm the date of investment and the price of purchase/sale.",
        heading: "Confirm Investment",
        investedAt: {
          label: "Investment Date",
          required: "Investment date is mandatory",
        },
        submit: "Confirm",
        unitPrice: {
          labelBuy: "Unit Price Bought",
          labelSell: "Unit Price Sold",
          label: "Unit Price (AED/Unit)",
          required: "Unit price is mandatory",
        },
      },
    },
    marketOrders: {
      grid: {
        title: "Market Orders",
        units: "Units",
        description:
          "Track fund allocation and redeption instructions and their status",
        fundId: "Fund ID",
        fundName: "Fund Name",
        fundIsin: "Fund ISIN",
        type: "Type",
        amount: "Amount",
        actions: "Actions",
        submittedOn: "Submitted On",
        transferBy: "Transfer by",
      },
      execute: "Execute",
      confirm: {
        unitsAmount: {
          labelBuy: "Units Bought",
          labelSell: "Units Sold",
          match:
            "This number does does not match the specified unit price. Please double check both values.",
          required: "Units are mandatory",
        },
        cancel: "Cancel",
        description:
          "Please, confirm the date of market order and the price of purchase/sale.",
        heading: "Confirm Market Order",
        executedAt: {
          label: "Market Order Date",
          required: "Market Order date is mandatory",
        },
        submit: "Confirm",
        unitPrice: {
          labelBuy: "Unit Price Bought",
          labelSell: "Unit Price Sold",
          label: "Unit Price (AED/Unit)",
          required: "Unit price is mandatory",
        },
        receipt: {
          heading: "Receipt",
          fileRequirements: "PDF, PNG or JPG (max. 2MB)",
          orDragAndDrop: "or drag and drop",
          clickToUpload: "Click to upload",
        },
      },
    },
    companySettings: {
      title: "Company settings",
      admins: {
        adminEmail: "Admin email",
        adminUsers: "Admin users",
        newAdminUser: "Invite admin",
        adminUsersInfo: "They'll require approval for scheme changes.",
        deleteAdmin: {
          title: "Delete admin",
          description: "Are you sure you want to delete admin?",
        },
        inviteAdmins: {
          title: "Invite company admins",
          description: "Invite admins to collaborate on this.",
          assign: "Send Invitation",
        },
        empty: "No admins assigned.",
      },
      assignEmployees: {
        header: "Assign Employees to your group",
        button: "Assign employees",
        columns: {
          servicePeriod: "Service period",
        },
      },
      general: {
        cancel: "Cancel",
        info: {
          heading: "Company info",
          name: {
            label: "Company name",
            required: "Company name is required",
          },
          number: {
            label: "Registration number",
            required: "Registration number is required",
          },
          address: {
            label: "Registered address",
            required: "Registered address is required",
          },
          website: {
            label: "Website",
          },
        },
        submit: "Save changes",
      },
      logo: {
        heading: "Company Logo",
        fileRequirements: "PNG, JPG or GIF (max. 2MB)",
        orDragAndDrop: "or drag and drop",
        clickToUpload: "Click to upload",
        description: "Upload your company logo",
      },
      saml: {
        domainVerificationHeading: "Domain verification",
        domain: {
          label: "Domain",
          required: "Domain name is required",
          hint: "Enter the the domain you want to verify",
          placeholder: "Enter domain",
          regenerateTxtRecord: "Regenerate TXT record",
          edit: "Edit domain",
          verify: "Verify domain",
          verificationSucceeded: "The domain verification succeeded.",
          verificationFailed:
            "The domain verification has failed, please try again later.",
        },
        identityProviderUrl: {
          hint: "Enter the Metadata URL provided by your IDP",
          label: "Identity Provider Metadata URL",
          placeholder: "https://",
          required: "The identity provider URL is a required field.",
          updateSucceeded: "The identity provider URL has been updated.",
        },
        assertionConsumerServiceUrl: {
          hint: "Enter this in your IDP’s SAML configuration.",
          label: "Assertion Consumer Service (ACS) URL",
        },
        addDomain: "Add domain",
        instructionsPart1: "Add the following TXT record to DNS for",
        instructionsPart2: "domain",
        completedVerificationPart1: "The verification for domain ",
        completedVerificationPart2: "has been completed successfully.",
        ssoConfigurationHeading: "SAML SSO Configuration",
        ssoConfigurationDescription:
          "Configure SAML SSO for your Aurem workspace with your Identity Provider (IdP)",
        metadataType: "Metadata type",
        urlMetadataType: "URL to metadata XML",
        urlMetadataTypeSubtitle: "Enter your IdP SAML metadata public URL",
        fileMetadataType: "Metadata XML file upload",
        fileMetadataTypeSubtitle: "Upload your IdP metadata file",
        metadataFile: {
          required: "IdP metadata file is a required field",
          requirements: "XML file (max. 2MB)",
        },
        metadata: {
          hint: "Use this URL for quicker configuration of your Identity Provider (IdP)",
          label: "Service Provider (SP) Metadata URL",
        },
        copiedMetadataUrl: "The Metadata URL has been copied.",
        copiedAssertionConsumerServiceUrl: "The ACS URL has been copied.",
        downloadCertificate: "Download certificate",
      },
      services: {
        servicesProvided: "Services provided",
        ewa: {
          title: "Salary Advance",
          subtitle: "Allow your employees to benefit from salary advances",
          enable: "Enable",
          disable: "Disable",
          agree: "I Agree",
          disagree: "I Disagree",
          enableToast: "Salary advance service enabled",
          disableToast: "Salary advance service disabled",
          terms: [
            "Salary Advance Processing Terms & Conditions",
            "Salary Advance Processing is provided by CredibleX, a limited liability company regulated by the ADGM Financial Services Regulatory Authority with registration number 220134.",
            "1.Salary Advance Request",
            "1.1 If an employee of Employer requests a salary advance on the App, CredibleX shall process and disburse the requested advance on behalf of the Employer, provided that CredibleX is satisfied that the Employer has provided CredibleX with all necessary documentation in conformation with said service.",
            "2.Invoicing and Payment",
            "2.1 CredibleX shall issue an invoice to the Employer on an agreed-upon date each month. This invoice shall reflect the total value of all salary advances requested by the Employer employees during the previous period.",
            "2.2 The payment terms for the invoice shall be 5 working days from the date of issuance.",
            "3.Payroll Adjustments",
            "3.1 Employer will ensure enough funds are available at their disposal and shall ensure to make such transfers of funds to CredibleX against invoices created and submitted by CredibleX to Employer in relation to the employees Salary Advance Requests.",
            "3.2 CredibleX shall ensure that all new requests are logged in and shared in a timely manner with the Employer to ensure the Employer would make all the necessary adjustments in relation to employees affected salaries.",
            "4.Employer's Responsibility",
            "4.1 The Employer shall be solely responsible to make all necessary arrangements to refund CredibleX upon an invoice submission the full amount owed to CredibleX within 5 working days from the issuance of such an invoice. Failure to which, a penalty of 9% of the value of the invoice will incur monthly or any part thereof until the full refund is received back by CredibleX. The Employer will also be subjected to temporary/final suspension and access restricted to services offered under CredibleX and/or Aurem and/or any third service provider at the sole discretion of the affected service provider.",
            "All other terms and conditions stated in Aurem main T&Cs shall remain unchanged and the Employer will be subjected to it to the extent not referenced herein.",
          ],
          disableService: {
            disableTime:
              "This service was disabled by you and will be discontinued on ",
            owedAmount: "The amount owed in the current cycle is",
            makePayment:
              "If you have already made this payment, thank you! If not, your prompt payment is appreciated.",
          },
          disableServiceDialog: {
            title: "Are you sure?",
            subtitle: "You are trying to disable the salary advance service.",
            disableDate: "This will be disabled on ",
            employeesNotified:
              "Employees enrolled in this service will be notified about this.",
          },
        },
      },
      subscription: {
        title: "Subscription details",
        description: "Aurem subscription is required for company verification",
        activeSubscriptionText: "You are currently subscribed to this plan:",
        noSubscriptionText: "You haven't subscribed to Aurem yet.",
        subscribeButton: "Subscribe",
        sudoSubscribeButton: "Sudo subscribe",
        cancelButton: "Cancel subscription",
        paymentSuccessHeading: "Payment Succeeded",
        paymentSuccessDescription:
          "Your payment for Aurem subscription has been successfully received.",
        paymentCanceledHeading: "Payment Canceled",
        paymentCanceledDescription:
          "Your payment for Aurem subscription has failed due to cancelation.",
      },
    },
  },
  pages: {
    helpRequest: {
      helpForm: "Help form",
      issue: {
        description: "Describe the issue you have encountered",
        label: "Issue description*",
        placeholder: "Issue description",
        required: "The issue description is a required field",
      },
      file: {
        clickToUpload: "Click to select a file for attaching",
        orDragAndDrop: "or drag and drop here",
        fileRequirements: "PDF, DOCX, DOC, PNG, JPG, GIF, or ZIP (max. 2MB)",
      },
      success:
        "Your query has been successfully registered. Our team will reach out to you in next 24 hours to resolve your query.",
      generalError: "Not all fields filled in correctly!",
    },
    companyDetails: {
      tabTitle: {
        dashboard: "Dashboard",
        company: "General",
        admins: "Admins",
        employees: "Employees",
        benefits: "Benefits",
        payments: "Payments",
        documents: "Documents",
        trading: "Trading",
        verification: "Verification",
        samlConfiguration: "SAML configuration",
        services: "Services",
        subscription: "Subscription",
      },
    },
    companyInstruction: {
      heading: "Company created!",
      body: [
        "To enable payment capabilities and invite employees, your business must first be verified.",
        "This involves providing details about your business activity, which should take about 7-10 minutes.",
        "Afterward, we'll send you a list of documents to upload.",
      ],
      listLabel: "Before you begin, please have information ready regarding",
      listContent: [
        "The purpose and functions of your organization",
        "Revenue and assets, including the source of funds and the value of assets",
        "Origin of equity and funds",
        "Tax-related information",
        "Information about the ultimate beneficiary owner, whether it be a private individual(s) or a company",
      ],
      skipButtonText: "Skip for now",
      startButtonText: "Start verification",
    },
    requests: {
      heading: "Requests",
      subHeading: "Review new company requests for approval",
      grid: {
        columns: {
          companyName: "Company name",
          decidedBy: "Decided by",
          status: "Status",
          submittedOn: "Submitted on",
        },
        status: {
          submitted: "Details Submitted",
          signatureRequired: "Waiting for Signature",
          detailsInReview: "Details in Review",
          documentsInReview: "Documents in Review",
          documentsRequired: "Waiting for Documents Submission",
          documentsRetry: "Waiting for Documents Resubmission",
        },
      },

      signatureRequest: {
        heading: "Signed documents",
        deedDocument: "Deed of Admission document",
        companyDocument: "Company document",
        waitingForSignature: "Waiting for the user to sign the document",
        download: "Download",
      },

      detailsApprovalModal: {
        heading: "Request Documents for Verification",
        button: {
          addOther: "Add Other",
          submit: "Send Request",
        },
        companyAndOwnership: {
          title: "Company & Ownership",
          defaultOptions: [
            "Valid Trade License (or Certificate of incorporation)",
            "Memorandum & Articles of Association",
            "Structure chart (Signed and dated)",
            "Register of Directors",
            "Register of Members",
            "Last Audited Financial",
            "Business Registration",
            "Proof of Operating Address in UAE",
          ],
        },
        individualIds: {
          title: "Individual IDs",
          defaultOptions: [
            "Individual director documents",
            "Individual authorized signatory documents",
          ],
        },
        alert: {
          successMessage: "Documents request sent successfully",
        },
      },

      requestMoreDocumentsModal: {
        heading: "Request More Documents",
        alert: {
          successMessage: "Documents request sent successfully",
        },
      },

      documentsResubmissionModal: {
        heading: "Request Documents Resubmission",
        companyDocuments: "Company Documents",
        cancelButton: "Cancel",
        submitButton: "Send Request",
        successMessage: "Documents resubmission request sent",
        remarkLabel: "Remark",
      },

      rejectRequestAlertDialog: {
        alert: {
          successMessage: "Rejected rejected successfully",
        },
        button: {
          cancel: "Not now",
          submit: "Confirm Rejection",
        },
        heading: "Reject Verification Request",
        subHeading: "Are you sure? You can't undo this action afterwards.",
      },
    },
    requestDetails: {
      tabTitle: {
        businessActivities: "Business activities",
        company: "Company",
        documents: "Documents",
      },
      documentsTab: {
        companyDocuments: {
          heading: "Company & Ownership",
        },
        footer: {
          heading: "Ready to approve?",
          subHeading:
            "Now that you have reviewed the business activities, you can request more documents to complete the KYB process.",
          rejectButton: "Reject",
          requestMoreButton: "Request More",
          resubmitButton: "Request Resubmission",
          approveButton: "Approve",
        },
        approveDialog: {
          successMessage: "Company verification request approved",
          heading: "Approve Verification Request",
          subHeading: "Are you sure? You can't undo this action afterwards.",
          cancelButton: "Cancel",
          confirmButton: "Confirm Approval",
        },
        resubmittedDocuments: "Resubmitted",
      },
    },

    payment: {
      details: {
        tabTitle: {
          matching: "Matching",
          LTIP: "LTIP",
          gratuity: "Gratuity",
        },
      },
    },
    benefits: {
      setUpNewPlan: "Set up new plan",
      benefitPlans: "Benefit plans",
      benefitPlansDescription:
        "Manage and enrol employees on your company’s benefit plans",
      benefitEmployeeSubtitle: "Manage employee's benefit plans here",
      empty: "No benefits created.",
      employeeEmpty: "No benefits assigned.",
      newBenefitModalHeading:
        "Select a type of benefit plan you want to set up",
      benefitTypes: {
        gratuity: "Gratuity plan",
        saving: "Saving plan",
        incentive: "Incentive plan",
        custom: "Custom benefit plan",
        gratuitySubtitle: "Manage or fund your End of Service Benefits",
        savingSubtitle: "A savings plan where you offer a matching amount",
        incentiveSubtitle:
          "A bonus paid today that can be accessed in the future",
        customSubtitle:
          "Make your own benefit plan to cater to your company's needs",
      },
      planName: "Plan Name",
      removeEmployees: "Remove employees",
      totalPaid: "Total paid",
      type: "Plan Type",
      startsOn: "Start Date",
      upcomingDepositDate: "Upcoming Deposit Date",
      fundId: "Default Fund",
      contribution: "Contribution Limit",
      employeesCount: "No. of Employees",
      assignEmployees: "Assign employees",
      deposits: {
        makeDeposit: "Make a deposit",
        whatKindOfDeposit: "What kind of deposit do you want to make?",
        distributedToEmployees: "Distributed to specific employees in the plan",
        lumpsumForEmployees: "Lumpsum for employees",
        distributedAcrossAllEmployees:
          "Distributed across all employees in the plan",
        lumpsumForCompany: "Lumpsum for the company",
        uploadListOfEmployees: "Upload a list of employees with their details",
        uploadButton: "Upload",
        uploadListOfEmployeesDescription:
          "Upload a csv list of employees with the following details:",
        employeeFirstName: "Employee First Name",
        employeeLastName: "Employee Last Name",
        employeeEmiratedId: "Employee Emirates ID",
        companyAmount: "Company Amount",
        employeeAmount: "Employee Amount",
        noLiability: "No liability",
        requestHasBeenSubmitted: "The request has been submitted",
        remarks: "Remarks (optional)",
        howMuchYouWantToDeposit: "How much do you want to deposit?",
        currentLiability: "Current liability",
        totalAmount: {
          label: "Deposit amount",
          required: "Field is required",
          placeholder: "Enter amount",
          invalid: "Invalid amount",
        },
        successSubtitle:
          "The request has been submitted, a payment will be created shortly.",
      },
    },
    editBenefit: {
      assignEmployees: "Assign Employees",
      checkLiability: "Check Liability",
      editedHeading: "Changes saved",
      editedSubtitle: "Changes to the benefit plan have now been saved",
      employees: "Employees",
      employeesSubtitle: "Manage employees for this plan here.",
      executeProduct: "Execute product",
      makeDeposit: "Make deposit",
      removeEmployees: "Remove employees",
      youAreTryingToRemove:
        "Please confirm that you understand that this action will remove employee(s) from the benefit plan.",
      grid: {
        endsOn: "Benefit left",
        startedOn: "Benefit joined",
      },
    },
    ewa: {
      configuration: {
        heading: "Settings",
        subtitle:
          "Manage eligibility parameters so that employees can make requests that fit company policies",
      },
      employees: {
        assignEmployeesHeading: "Assign employees to Salary Advance",
        heading: "Employees",
        subtitle: "Manage employees who are eligible for salary advance",
      },
    },
    newBenefit: {
      heading: "Set up a new benefit plan",
      breadcrumb: "Set up new plan",
      autoAssign: {
        heading: "Assign to new employees",
        description: "New employees will be automatically added to this plan",
      },
      planName: "Benefit plan name",
      planType: "Benefit plan type",
      planStartDate: "Benefit plan start date",
      planStartDateSubtitle:
        "This is when you want the plan to be effective from",
      defaultFund: "Default fund",
      defaultFundSubtitle: "Choose from our world-class funds",
      planNamePlaceholder: "Eg. Senior Management",
      gratuitySubtitle: "End of service benefits",
      savingsSubtitle:
        "Match a certain amount of what your employees contribute",
      incentiveSubtitle: "Long term investments for added incentives",
      browserFactSheets: "Browse fact sheets",
      errors: {
        nameNotPresent: "Please fill in benefit name",
        fundNotPresent: "Please choose the fund",
        contributionNotPresent: "Please enter contribution",
      },
      benefitPlanDetails: "Benefit plan details",
      benefitPlanDetailsSubtitle: "Configure your payment settings",
      frequencyOfContributions: "Frequency of contributions",
      frequencyOfContributionsTooltip:
        "How regularly do you want to make a payment",
      monthly: "Monthly",
      yearly: "Yearly",
      dateOfDeposit: "Date of deposit",
      contributionType: "Contribution Type",
      contributionTypeTooltip: "How you want to calculate contribution?",
      percentage: "Percentage",
      absoluteValue: "Fixed amount in AED",
      baseContributionOn: "Base Contribution on",
      baseContributionOnTooltip:
        "From which salary type you want to calculate contribution?",
      basicSalary: "Basic Salary",
      fullSalary: "Full Salary",
      contributionAmount: "Contribution Amount",
      vesting: "Vesting",
      created: "The benefit plan has been created successfully.",
      benefitCreatedHeading: "Benefit plan Created!",
      benefitCreatedSubtitle:
        "Now you can assign employees to your benefit plan",
    },
    assignEmployeesToBenefit: {
      breadcrumb: "Assign Employees",
      heading: "Assign Employees to this plan",
      empty: "All employees have been added to this plan.",
      assignEmployees: "Assign employees",
      assignedHeading: "You’re all set up!",
      assignedSubtitle:
        "Your benefit plan is set up and employees assigned. You will now see this benefit plan in your Dashboard.",
    },
    companyVerification: {
      heading: "Provide details about business activities",
      subHeading:
        "In order to access payment capabilities and invite employees, we need to verify your business. Please provide the below information.",
      completedView: {
        heading: "Verification completed",
        subHeading: "Your company has been successfully verified",
      },
      submittedView: {
        heading: "Waiting for digital signature",
        subHeading:
          "Your details are submitted and we have send you a digital signature request on your email. Please sign the document to start the verification process. If you have not received the email, please check your spam folder or contact us",
      },
      inProgressView: {
        heading: "Verification in progress",
        content: [
          "Thank you for providing information about your business activities",
          "Our team is reviewing this information and will get back to you with further documents required to complete the process.",
          "This usually takes 10 days to complete.",
          "Check back again later.",
        ],
      },
      updateForm: {
        basicInfo: {
          heading: "Basic Information",
          organizationPurpose: {
            label: "Purpose of your organization",
            placeholder: "Enter a description...",
            description: "Write about what your organisation exists to do.",
          },
          businessActivities: {
            label: "Business activities carried out by your organisation",
            placeholder: "Enter a description...",
            description:
              "Describe in detail the business activities carried out by your organisation",
          },
        },
        revenueAndAssets: {
          heading: "Revenue and Assets",
          subHeading: "To understand the scale and value of the organisation",
          expectedTurnover: {
            label: "Expected turnover per annum in USD",
            placeholder: "Enter amount",
          },
          fundSource: {
            label: "Source of funds",
            description:
              "How is the revenue recieved? e.g. in the form of sales, dividends, interest, etc.",
          },
          revenueFrequency: {
            label: "Frequency of revenue recieved",
            placeholder: "How many times a year",
            description:
              "How often in a year does the organisation receive payments?",
          },
          employeesCount: {
            label: "Number of employees",
            placeholder: "100",
          },
          assetsValue: {
            label: "Value of the organisation’s assets in USD",
            placeholder: "Enter amount",
            description: "Estimated current assets",
          },
        },
        originOfFunds: {
          heading: "Origin of equity and funds",
          equityOrigin: {
            label: "What is the origin of the organisation’s equity?",
            placeholder: "Enter a description...",
            description:
              "Describe the type of payments, volume, country and dates",
          },
          beneficiaryCountry: {
            label: "Country of the beneficiary of these funds",
          },
        },
        fatca: {
          heading:
            "Foreign Account Tax Compliance Act (FATCA) or Common Reporting Standard (CRS)",
          organizationQualification: {
            label: "What does your organisation qualify as?",
            options: {
              ffi: "Foreign Financial Institutions (FFI) / Financial Institution (FI)",
              activeNfe:
                "Active Non-Financial Entity (NFE) / Non-Financial Foreign Entity (NFFE)",
              passiveNfe:
                "Passive Non-Financial Entity (NFE) / Non-Financial Foreign Entity (NFFE)",
              other: "Other",
            },
          },
          otherOrganizationQualification: {
            label: "Specify if other",
          },
          giin: {
            label: "Global Intermediary Identification Number",
          },
          taxRegistrationNumber: {
            label: "Local tax registration number",
          },
        },
        ubo: {
          heading: "Ultimate Beneficial Owner (UBO)",
          uboType: {
            label: "The UBO is a",
            options: {
              private: "Private person",
              regulated:
                "Stock-listed company / Financially regulated entity / Fund",
            },
          },
          directors: {
            label:
              "Majority shareholder of the company and other shareholders who owns more than 25% in the company",
            addDirectorButton: "Add director",
            form: {
              firstName: {
                label: "First name",
              },
              lastName: {
                label: "Last name",
              },
              nationality: {
                label: "Nationality",
              },
              taxResidence: {
                label: "Tax residence",
              },
              residentialAddress: {
                label: "Residential address",
                description: "Street, city, country",
              },
              phoneNumber: {
                label: "Phone number",
              },
              email: {
                label: "Email address",
              },
              dateOfBirth: {
                label: "Date of birth",
              },
              maritalStatus: {
                label: "Marital status",
                options: {
                  single: "Single",
                  married: "Married",
                },
              },
              profession: {
                label: "Profession and actual function",
              },
              annualIncome: {
                label: "Annual income",
                options: {
                  lessThan100k: "<100,000 USD",
                  between100kAnd400k: "100,000-400,000 USD",
                  between400kAnd1m: "400,000-1million USD",
                  moreThan1m: ">1million USD",
                },
              },
              estimatedWealth: {
                label: "Range of estimated wealth",
                options: {
                  lessThan1m: "<1million USD",
                  between1mAnd5m: "1-5million USD",
                  between5mAnd20m: "5-20million USD",
                  between20mAnd100m: "20-100million USD",
                  moreThan100m: ">100million USD",
                },
              },
              wealthSource: {
                label: "Source of wealth",
              },
              isUsTaxpayer: {
                label:
                  "Does the UBO have a US Taxpayer Identification Number (TIN)?",
                options: {
                  yes: "Yes",
                  no: "No",
                },
              },
              taxpayerTin: {
                label: "US Taxpayer Identification Number (TIN)",
              },
              button: {
                save: "Save",
                cancel: "Cancel",
                update: "Update",
                remove: "Remove",
              },
            },
          },
          entity: {
            entityName: {
              label: "Entity name",
            },
            address: {
              description: "Street, city, country",
              label: "Address",
            },
            website: {
              label: "Website home page",
            },
            authority: {
              label: "Stock exchange / Financial authority",
            },
            dateOfIncorporation: {
              label: "Date of incorporation",
            },
          },
        },
        submit: {
          heading: "Ready to submit?",
          sudoVerify: "Sudo verify",
          subHeading:
            "Once submitted, We will get back to you with extra information we need to verify your business. This usually takes less than 10 working days.",
          saveButton: "Save for later",
          submitDetailsButton: "Submit for Approval",
        },
        approveDetails: {
          heading: "Ready for KYB?",
          subHeading:
            "Now that you have reviewed the business activities, you can request more documents to complete the KYB process.",
          rejectButton: "Reject",
          submitButton: "Request more information",
        },
        toastMessage: {
          save: "Your details have been saved successfully",
          submit: "Your details have been submitted successfully",
        },
      },
      documentsUpload: {
        heading: "Provide documents to complete verification",
        subHeading:
          "Based on the information provided by you, we need the following documents to verify your business",
        companyDocuments: {
          heading: "Company & Ownership",
        },
        formFooter: {
          heading: "Ready to submit?",
          subHeading:
            "Once submitted, your documents and details will be reviewed by the trustee. Approvals usually take 2-3 business days.",
          submitButton: "Submit for Approval",
        },
        successMessage: "Documents have been submitted for approval",
        upload: {
          loadingText: "Uploading...",
          clickToUpload: "Click to upload",
          dragAndDrop: "or drag and drop",
          fileType: "PDF (max 5MB)",
          successMessage: "Document uploaded",
          removeMessage: "Document removed",
        },
      },
      documentsDelete: {
        deleteButton: "Delete",
        deleting: "Deleting...",
        successMessage: "Document deleted",
      },
      documentsResubmit: {
        clickToResubmit: "Click to reupload",
      },
      subscriptionRequiredModal: {
        heading: "Subscription required",
        subHeading:
          "You must be subscribed to Aurem in order to verify company",
        submitButton: "Go to subscription",
        cancelButton: "Not now",
      },
      submitDetailsAlert: {
        heading: "Submit verification details for approval",
        subHeading:
          "Once details submitted cannot be undone, we will get back to you with extra information we need to verify your business. This usually takes less than 10 working days.",
        submitButton: "Confirm submission",
        cancelButton: "Not now",
      },
    },
  },
  healing: {
    date: {
      corrected:
        "The date was automatically updated to the format DD/MM/YYYY. If this is incorrect, please edit and upload the file again.",
    },
    decimal: {
      corrected:
        "The number has been updated to the format of 10000.00 if this is incorrect please edit the file and upload again.",
    },
  },
  validation: {
    confirmPassword: {
      match: "كلمة المرور غير متطابقة",
      required: "الرجاء إدخال تأكيد كلمة المرور",
    },
    eid: {
      required: "Emirates ID is required",
    },
    email: {
      required: "البريد الإلكتروني مطلوب",
      format: "الرجاء إدخال بريد إلكتروني صحيح",
    },
    iban: {
      required: "IBAN is required",
      invalid: "Invalid IBAN",
      notUAE: "Only UAE IBANs are allowed",
    },
    number: {
      format: "Number value required",
      invalid: "Number is invalid",
      negativeOrInvalid:
        "Numbers must be in the format 10000.00 with numbers only and can be to 2 decimal places",
    },
    password: {
      required: "كلمة المرور مطلوبة",
      minLength: "كلمة المرور يجب أن تكون 8 أحرف على الأقل",
      match: "كلمة المرور غير متطابقة",
    },
    date: {
      required: "Date is required",
      invalid: "Date is invalid",
      future: "Date cannot be in the future",
      invalidFormat: "Must be DD/MM/YYYY",
    },
    nationality: {
      invalid: "Nationality must be capitalized and existing.",
      invalidWithHint: (hint: string) => `Did you mean ${hint}?`,
    },
    endDate: {
      invalid: "End date must be after start date",
    },
    required: (field) => `${field} is required`,
  },
};
