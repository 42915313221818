import { gql } from "apollo/types";

export const VERIFY_EMAIL = gql(`
  mutation VerifyEmail($verificationId: String!) {
    verifyEmail(verificationId: $verificationId) {
      email
    }
  }
`);

export const RESEND_VERIFICATION_EMAIL = gql(`
  mutation ResendVerificationEmail {
    resendVerificationEmail
  }
`);

export const SIGN_OUT = gql(`
mutation DeleteCurrentUserSession {
  deleteCurrentUserSession
}
`);
