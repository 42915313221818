import { ar } from "./ar";
import { Translations } from "./types";

/**
 * Creates a Proxy for the translation object, printing the path to the property being accessed instead of its value
 */
const debugHandler: ProxyHandler<Translations> & { path: string } = {
  path: "t",
  get<T>(target: T, prop: keyof T) {
    const newPath = `${this.path}.${String(prop)}`;
    switch (typeof target[prop]) {
      default: {
        return newPath;
      }
      case "function": {
        return (...args: unknown[]) => `${newPath}(${args})`;
      }
      case "object": {
        const handler = Object.create(this, {
          path: {
            value: newPath,
          },
        });
        return new Proxy(target[prop] as object, handler);
      }
    }
  },
};

// The target doesn't need to be the english translation specifically. It just needs to be an object with a complete implementation of the Translations interface, because we can't use Typescript types at runtime to generate this proxy.
export const debug: Translations = new Proxy(ar, debugHandler);
