import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import * as React from "react";
import { Circle } from "react-feather";
import { cn } from "utils/tailwind-merge";

import { Label } from "../Label";

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn("grid gap-2", className)}
      {...props}
      ref={ref}
    />
  );
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> & {
    hint?: string;
    label: string;
  }
>(({ className, hint, label, ...props }, ref) => {
  return (
    <div>
      <div className={cn("flex items-center space-x-2", className)}>
        <RadioGroupPrimitive.Item
          className="aspect-square h-4 w-4 rounded-full border border-new-neutral60 text-new-neutral60 ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
          id={label}
          ref={ref}
          {...props}
        >
          <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
            <Circle className="h-2 w-2 fill-current text-current" />
          </RadioGroupPrimitive.Indicator>
        </RadioGroupPrimitive.Item>
        <Label className="text-new-neutral60" htmlFor={label}>
          {label}
        </Label>
      </div>
      {hint && <p className="ml-6 text-sm text-new-neutral50">{hint}</p>}
    </div>
  );
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

export { RadioGroup, RadioGroupItem };
