import components from "./components";

export default {
  components,
  styles: {
    global: {
      "html, body": {
        minHeight: "100vh",
        color: "gray.900",
        bgColor: "white",
      },
    },
  },
  fonts: {
    heading: `Suisse`,
    body: `Suisse`,
  },
  colors: {
    primary: {
      25: "#f3f7f2",
      50: "#e4ebdf",
      100: "#d6e5d1",
      200: "#b9d1b6",
      300: "#9ab59a",
      400: "#82a387",
      500: "#6f9479",
      600: "#577d67",
      700: "#3f6951",
      800: "#2a543d",
      900: "#19402b",
    },
    gray: {
      25: "#FCFCFD",
      50: "#FBFBF9",
      100: "#F2F4F7",
      200: "#ECEAE4",
      300: "#D0D5DD",
      400: "#98A2B3",
      500: "#667085",
      600: "#475467",
      700: "#54514A",
      800: "#1D2939",
      900: "#101828",
    },
    tableGray: {
      100: "#F9FAFB",
    },
    yellow: {
      600: "#DEB42C",
    },
    primaryButton: {
      25: "#F6FBF9",
      50: "#EDF6F2",
      100: "#E3F1EC",
      200: "#C8E4D8",
      300: "#ACD6C4",
      400: "#91C8B1",
      500: "#47A47D",
      600: "#2B805C",
      700: "#20584E",
      800: "#0D4238",
      900: "#0D2723",
    },
    brand: {
      25: "#F6FBF9",
      50: "#EDF6F2",
      100: "#E3F1EC",
      200: "#C8E4D8",
      300: "#ACD6C4",
      400: "#91C8B1",
      500: "#75BB9E",
      600: "#47A47D",
      700: "#2B805C",
      800: "#0D4238",
      900: "#0D2723",
    },
    error: {
      25: "#FCF8F5",
      50: "#F9F0EB",
      100: "#F1D9CD",
      200: "#E9C3AF",
      300: "#E3B49B",
      400: "#D89573",
      500: "#CD774B",
      600: "#C76837",
      700: "#C75137",
      800: "#911F18",
      900: "#7A271A",
    },
    warning: {
      25: "#FFFCF5",
      50: "#FFFAEB",
      100: "#FEF0C7",
      200: "#FEDF89",
      300: "#FEC84B",
      400: "#FDB022",
      500: "#F79009",
      600: "#DC6803",
      700: "#B54708",
      800: "#93370D",
      900: "#7A2E0E",
    },
    green: {
      600: "#577D67",
      900: "#19402B",
    },
    purple: {
      100: "rgba(127, 86, 217, 0)",
      200: "rgba(127, 86, 217, 0.1)",
      500: "#7F56D9",
    },
    success: {
      800: "#05603A",
    },
    brown: {
      50: "#fdf8f6",
      100: "#f2e8e5",
      200: "#eaddd7",
      300: "#e0cec7",
      400: "#d2bab0",
      500: "#bfa094",
      600: "#a18072",
      700: "#977669",
      800: "#846358",
      900: "#43302b",
    },
  },
};
