import { Quiz } from "apollo/types/graphql";
import { Button } from "components/new/Button";
import { ContestParams, quizEditRoute } from "const/routes";
import { t } from "i18n";
import { Link, useParams } from "react-router-dom";

import { useContestAdmin } from "./hooks";

const Quizzes = () => {
  const { contestId } = useParams<ContestParams>() as ContestParams;

  const { data } = useContestAdmin(contestId);
  const quizzes = data?.contest.contest.quizzes;
  return (
    <div className="flex w-full flex-col items-center p-5">
      {quizzes?.map((quiz: Partial<Quiz>) => (
        <>
          <div className="mt-3 flex w-full flex-row items-center">
            <div className="flex h-10 w-10 items-center justify-center">
              {!quiz.isPublished ? (
                <div className="h-2.5 w-2.5 rounded-full bg-[#205FDA]" />
              ) : (
                <div className="h-2.5 w-2.5 rounded-full bg-gray-400" />
              )}
            </div>
            {!quiz.isPublished ? (
              <h1 className="mr-2 grow text-right text-xl">{quiz.title}</h1>
            ) : (
              <h1 className="mr-2 text-right text-xl text-gray-400">
                {quiz.title}
              </h1>
            )}
            {!quiz.isPublished && (
              <Link to={quizEditRoute({ quizId: quiz.id })}>
                <Button btnStyle="white">{t.contest.admin.editQuiz}</Button>
              </Link>
            )}
          </div>

          <div className="mt-3 h-0.5 w-full bg-gray-100" />
        </>
      ))}
    </div>
  );
};

export default Quizzes;
