import NavigationBar from "components/NavigationBar";
import { Toaster } from "components/new/Toast";
import ROUTES from "const/routes";
import {
  useInitUserQuery,
  useUserData,
  useUserPermissions,
} from "graphql/user";
import AdminContestPage from "pages/AdminContest";
import AdminHomePage from "pages/AdminHome";
import ContestPage from "pages/Contest";
import EditQuizPage from "pages/EditQuiz";
import Profile from "pages/profile";
import QuizPage from "pages/Quiz";
import { SuccessModalProvider } from "providers/SuccessModal";
import { WarningModalProvider } from "providers/WarningModal";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { classNames } from "utils/styles";

import { LayoutFullscreen } from "./LayoutFullscreen";

export function useAuthRoutes(): JSX.Element[] | null {
  const { loading: userLoading } = useInitUserQuery();
  const { isContestAdmin } = useUserPermissions();

  if (userLoading) return null;

  if (isContestAdmin) {
    return [
      <Route
        element={<AdminHomePage />}
        key={ROUTES.HOME_ADMIN}
        path={ROUTES.HOME_ADMIN}
      />,
      <Route
        element={<AdminContestPage />}
        key={ROUTES.CONTEST_ADMIN}
        path={ROUTES.CONTEST_ADMIN}
      />,
      <Route
        element={<EditQuizPage />}
        key={ROUTES.QUIZ_EDIT}
        path={ROUTES.QUIZ_EDIT}
      />,
    ];
  }

  return [];
}

export const AuthRoutes: React.FC = () => {
  const authRoutes = useAuthRoutes();
  const user = useUserData();

  return (
    <>
      <WarningModalProvider>
        <SuccessModalProvider>
          <Routes>
            <Route element={<NavigationBar />}>
              <Route element={<LayoutFullscreen />}>
                {/* <Route element={<RouteValidator />} path="*" /> */}

                {authRoutes}

                {user && (
                  <Route
                    element={<Profile user={user} />}
                    key={ROUTES.PROFILE}
                    path={ROUTES.PROFILE}
                  />
                )}
                <Route
                  element={<ContestPage />}
                  key={ROUTES.CONTEST}
                  path={ROUTES.CONTEST}
                />

                <Route
                  element={<QuizPage />}
                  key={ROUTES.QUIZ}
                  path={ROUTES.QUIZ}
                />
              </Route>
            </Route>
          </Routes>
          <Toaster />
        </SuccessModalProvider>
      </WarningModalProvider>

      <LoaderOverlay shouldHide={authRoutes != null} />
    </>
  );
};

export type LoaderOverlayProps = {
  /** Set to true when the loader overlay should disappear */
  shouldHide: boolean;
};
/**
 * An overlay to be shown while the page is loading
 */
export const LoaderOverlay: React.FC<LoaderOverlayProps> = React.memo(
  function Loader({ shouldHide }) {
    const [hide, setHide] = React.useState(shouldHide);
    if (shouldHide) {
      setTimeout(() => {
        setHide(true);
      }, 300);
    }
    return hide ? null : (
      <div
        className={classNames(
          "fixed bottom-0 left-0 right-0 top-0 z-50 bg-gray50 transition-opacity duration-300",
          shouldHide ? "opacity-0" : "opacity-100",
        )}
      />
    );
  },
);
