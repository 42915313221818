import { useToast } from "@chakra-ui/react";
import { t } from "i18n";

type ToastProps = {
  description?: string;
  duration?: number;
};

export const useQuickToast = () => {
  const toast = useToast();

  const successToast = (props?: ToastProps) => {
    toast({
      title: t.common.success,
      description: props?.description || t.common.success,
      position: "top",
      status: "success",
      duration: props?.duration || 9000,
      isClosable: true,
    });
  };

  const errorToast = (props?: ToastProps) => {
    toast({
      title: t.common.error,
      description: props?.description || t.common.errorGeneric,
      position: "top",
      status: "error",
      duration: props?.duration || 5000,
      isClosable: true,
    });
  };

  const infoToast = (props?: ToastProps) => {
    toast({
      title: t.common.error,
      description: props?.description || t.common.errorGeneric,
      position: "top",
      status: "info",
      duration: props?.duration || 5000,
      isClosable: true,
    });
  };

  return { successToast, errorToast, infoToast };
};
