import { useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { gql } from "apollo/types";
import { Button } from "components/new/Button";
import { Card } from "components/new/Card";
import { FeaturedIcon } from "components/new/FeaturedIcon";
import { Form } from "components/new/Form";
import { FormInput } from "components/new/FormInput";
import { LinkButton } from "components/new/LinkButton";
import ROUTES from "const/routes";
import { t } from "i18n";
import React from "react";
import { Mail } from "react-feather";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { authLocationState } from "utils/authLocationState";
import * as z from "zod";

const REQUEST_PASSWORD_RESET = gql(`
mutation RequestPasswordReset($email: String!) {
  requestPasswordReset(email: $email)
}
`);

const formSchema = z.object({
  email: z.string().email(t.validation.email.format),
});

const ForgotPassword: React.FC = () => {
  const [success, setSuccess] = React.useState(false);
  const [requestReset, { error, loading }] = useMutation(
    REQUEST_PASSWORD_RESET,
  );

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: authLocationState.email ?? "",
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const { data } = await requestReset({ variables: values });
    if (data?.requestPasswordReset) {
      setSuccess(true);
    }
  };

  return (
    <div className="font-suisse flex w-screen max-w-[440px] flex-col gap-8 sm:p-6">
      <div className="flex flex-col gap-6 text-2xl">
        <h1 className="font-medium">{t.screen.forgotPassword.title}</h1>
      </div>
      <Card className="bg-new-neutral0">
        {success && (
          <div className="flex flex-col gap-6 p-8 text-new-neutral60">
            <FeaturedIcon>
              <Mail size={30} />
            </FeaturedIcon>
            <div className="flex flex-col gap-2">
              <p className="text-xl font-medium">
                {t.screen.forgotPassword.success.title}
              </p>
              <p>
                {t.screen.forgotPassword.success.description}{" "}
                {form.getValues().email}
              </p>
            </div>
          </div>
        )}
        {!success && (
          <Form
            className="flex flex-col gap-6 px-10 py-8"
            formContext={form}
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <p className="text-sm text-new-neutral60">
              {t.screen.forgotPassword.description}
            </p>
            <div className="flex flex-col gap-5">
              <FormInput
                control={form.control}
                label={t.screen.forgotPassword.email.label}
                name="email"
                placeholder={t.screen.forgotPassword.email.placeholder}
              />
              {error && (
                <p className="text-sm font-medium text-new-danger60">
                  {t.screen.forgotPassword.error}
                </p>
              )}
            </div>
            <div className="flex flex-col gap-4">
              <Button disabled={loading} type="submit">
                {t.screen.forgotPassword.submit}
              </Button>
              <Link className="flex justify-center" to={ROUTES.SIGN_IN}>
                <LinkButton>{t.screen.forgotPassword.cancel}</LinkButton>
              </Link>
            </div>
          </Form>
        )}
      </Card>
    </div>
  );
};

export default ForgotPassword;
