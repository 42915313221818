export default {
  variants: {
    navButton: () => ({
      color: "gray.700",
      _hover: {
        textDecoration: "underline",
      },
    }),
    navButtonSolid: () => ({
      color: "brand.700",
      bg: "brand.50",
      _hover: {
        textDecoration: "underline",
      },
      _active: {
        bg: "brand.200",
      },
    }),
  },
};
