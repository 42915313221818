export type AuthLocationState = {
  email?: string | null;
  from?: string | null;
  invitationId?: string | null;
};

/**
 * Non-reactive global state object meant as a type-safe alternative to location state for SignIn, SignUp, AuthRedirect, and other auth pages.
 */
export let authLocationState: AuthLocationState = {};

export function authLocationStateReset() {
  authLocationState = {};
}
