type Nullable<T extends object> = { [K in keyof T]: T[K] | null | undefined };

export const ROUTES = {
  EMAIL_VERIFICATION: "/email-verification/:verificationId",
  GOOGLE_REDIRECT: "/oauth/google/redirect",
  SAML_REDIRECT: "/saml/redirect",
  PASSWORD_FORGOTTEN: "/forgot-password",
  PASSWORD_RESET: "/password-reset/:requestId",
  PASSWORD_SETUP: "/password-setup",
  PROFILE: "/profile",
  RESET_PASSWORD: "/password-reset/:requestId",
  SETUP: "/setup",
  SIGN_IN: "/sign-in",
  SIGN_IN_WITH_SAML: "/sign-in-with-saml",
  SIGN_UP: "/sign-up",
  UNAUTHORISED: "/unauthorised",
  USERS: "/users",

  HOME: "/",
  HOME_ADMIN: "/admin",
  CONTEST: "/contest/:contestId",
  CONTEST_ADMIN: "/contest/admin/:contestId",
  QUIZ: "/quiz/:quizId",
  QUIZ_EDIT: "/quiz/edit/:quizId",
} as const;

type ValueOf<T> = T[keyof T];
export type AppRoute = ValueOf<typeof ROUTES>;

const replaceRouteParams = (
  params: Record<string, number | string | undefined | null>,
  route: AppRoute,
): string =>
  Object.entries(params).reduce((result, entries) => {
    const [key, value] = entries;
    if (value === null || value === undefined) {
      return result;
    }

    return result.replace(`:${key}`, `${value}`);
  }, route as string);

export type EmailVerificationParams = {
  verificationId: string;
};
export type RedirectAdminInvitationParams = {
  invitationId: string;
};
export type AcceptAdminInvitationParams = {
  invitationId: string;
};

export type PasswordResetParams = {
  requestId: string;
};

export type RequestDetailsParams = {
  companyId: string;
};

export type ContestParams = {
  contestId: string;
};

export const contestRoute = (params: Nullable<ContestParams>) =>
  replaceRouteParams(params, ROUTES.CONTEST);

export const contestAdminRoute = (params: Nullable<ContestParams>) =>
  replaceRouteParams(params, ROUTES.CONTEST_ADMIN);

export type QuizParams = {
  quizId: string;
};

export const quizRoute = (params: Nullable<QuizParams>) =>
  replaceRouteParams(params, ROUTES.QUIZ);

export const quizEditRoute = (params: Nullable<QuizParams>) =>
  replaceRouteParams(params, ROUTES.QUIZ_EDIT);

export default ROUTES;
