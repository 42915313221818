import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";
import { ArrowLeft, ArrowRight } from "react-feather";

import { cn } from "../../../utils/tailwind-merge";

const buttonVariants = cva(
  "inline-flex items-center justify-center font-suisse font-medium underline transition-colors",
  {
    variants: {
      size: {
        default: "text-sm",
        lg: "text-md",
      },
      disabled: {
        true: "text-new-neutral40",
        false: "text-new-neutral90",
      },
    },
    defaultVariants: {
      size: "default",
      disabled: false,
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  backArrow?: boolean;
  disabled?: boolean;
  forwardArrow?: boolean;
}

const LinkButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { backArrow, children, className, disabled, forwardArrow, size, ...props },
    ref,
  ) => {
    return (
      <button
        className={cn(
          buttonVariants({ size, className, disabled }),
          "font-suisse",
        )}
        disabled={disabled}
        ref={ref}
        {...props}
      >
        {backArrow && (
          <ArrowLeft
            className={cn(
              "mr-1",
              size === "default" ? "w-4" : "w-5",
              disabled ? "text-new-neutral40" : "text-new-accent",
            )}
          />
        )}
        {children}
        {forwardArrow && (
          <ArrowRight
            className={cn(
              "ml-1",
              size === "default" ? "w-4" : "w-5",
              disabled ? "text-new-neutral40" : "text-new-accent",
            )}
          />
        )}
      </button>
    );
  },
);
LinkButton.displayName = "LinkButton";

export { LinkButton, buttonVariants };
