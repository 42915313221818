import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  NextLink,
  Operation,
  TypedDocumentNode,
  from,
  fromPromise,
} from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { gql } from "apollo/types";
import { createUploadLink } from "apollo-upload-client";
import { print } from "graphql";
import React from "react";

import { AuthState, useAuth, useAuthDispatch } from "./AuthProvider";

const parseDate = (time: string) => new Date(parseInt(time));

const API_URL = process.env.REACT_APP_GRAPHQL_API ?? "";

const CACHE_CONFIG = {
  typePolicies: {
    Employee: {
      merge: true,
      fields: {
        startDate: {
          read: parseDate,
        },
        endDate: {
          read: parseDate,
        },
        createdAt: {
          read: parseDate,
        },
        updatedAt: {
          read: parseDate,
        },
      },
    },
  },
};

/**
 * Quick and dirty way of making GraphQL requests in places where the Apollo client may not be available.
 *
 * **DO NOT USE!;** unless you know exactly what you're doing
 */
async function fetchGQL<R, V>(
  url: string,
  query: TypedDocumentNode<R, V>,
  variables: V,
): Promise<{ data?: R }> {
  type Body = {
    operationName?: string;
    query: string;
    variables: V;
  };
  const body: Body = {
    variables,
    query: print(query),
  };
  const resp = await fetch(url, {
    body: JSON.stringify(body),
    method: "POST",
    headers: { ["Content-Type"]: "application/json" },
  });
  if (!resp.ok) {
    const message = await resp.text();
    throw new Error(message);
  }
  const json = await resp.json();
  return json;
}

type JWTPayload = Record<string, unknown> & {
  exp?: number;
};

/**
 * Parses the payload out of a JWT token, WITHOUT VERIFYING THE SIGNATURE
 */
function parseJWT(token: string): JWTPayload | null {
  try {
    // JWT tokens are composed of Base64 encoded HEADER, PAYLOAD, and VERIFY_SIGNATURE separated by '.'
    const [, base64Payload] = token.split(".");
    const payload = window.atob(base64Payload).toString();
    return JSON.parse(payload);
  } catch (err) {
    console.error(err);
    return null;
  }
}

/**
 * Parses the token and returns true if the expiry value is in the past
 */
function checkJWTExpired(token?: string | null): boolean {
  if (token == null) return true;
  const payload = parseJWT(token);
  if (payload?.exp == null) return true;
  const now = new Date();
  // Date.getTime() returns ms, but exp stores only seconds
  return payload.exp * 1000 < now.getTime();
}

async function createNewTokens(
  refreshToken: string,
): Promise<AuthState | null> {
  // We need to make a gql request to create new tokens, but don't have access to the Apollo client. Therefore we're using a custom fetch function.
  const { data } = await fetchGQL(
    API_URL,
    gql(`
  mutation CreateNewTokens($refreshToken: String!) {
    createNewTokens(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`),
    { refreshToken },
  );
  return data?.createNewTokens ?? null;
}

let isRefreshing = false;
let pendingRequests: (() => void)[] = [];
function resolvePendingRequests() {
  pendingRequests.forEach((cb) => cb());
  pendingRequests = [];
}

export const ApolloClientProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const auth = useAuth();
  const setAuth = useAuthDispatch();

  /**
   * Called if AuthLink doesn't catch an expired token
   *
   * Will refresh tokens and retry the request with the new token. If there are multiple concurrent requests, the others are added to a queue to be retried after the first request has successfully refreshed the tokens.
   *
   * @see https://stackoverflow.com/a/63386965/10297153
   */
  function refreshTokenAndRetry(forward: NextLink, operation: Operation) {
    if (auth?.refreshToken == null) return;
    // If access token expired, retry the request with a refreshed token
    if (!isRefreshing) {
      isRefreshing = true;
      return fromPromise(
        createNewTokens(auth.refreshToken)
          .then((newTokens) => {
            if (newTokens == null) return;
            setAuth(newTokens);
          })
          .catch(() => {
            resolvePendingRequests();
            setAuth(null);
          }),
      ).flatMap(() => {
        resolvePendingRequests();
        isRefreshing = false;
        return forward(operation);
      });
    } else {
      // If multiple requests are triggered around the same time, they have to wait for the first one to refresh the token
      return fromPromise(
        new Promise<void>((res) => pendingRequests.push(res)),
      ).flatMap(() => {
        return forward(operation);
      });
    }
  }
  /**
   * Called if AuthLink was in the process of refreshing the tokens and there were more than one requests.
   */
  function waitForTokenAndRetry(forward: NextLink, operation: Operation) {
    if (auth?.refreshToken == null) return;
    // If the refreshTokens request is still in progress, add the current request to the pending queue.
    // Otherwise retry right away.
    if (isRefreshing) {
      return fromPromise(
        new Promise<void>((res) => pendingRequests.push(res)),
      ).flatMap(() => {
        return forward(operation);
      });
    } else {
      return forward(operation);
    }
  }
  const errorLink = onError(({ forward, graphQLErrors = [], operation }) => {
    for (const err of graphQLErrors) {
      const {
        extensions: { code },
        message,
      } = err;
      if (code === "INTERNAL_SERVER_ERROR") {
        if (message === "Access token expired") {
          return refreshTokenAndRetry(forward, operation);
        }
      } else if (code === "FORBIDDEN") {
        if (message === "Access token is missing") {
          return waitForTokenAndRetry(forward, operation);
        }
      }
    }
  });

  async function getFreshAccessToken(): Promise<string | null> {
    if (auth == null) {
      return null;
    } else if (auth.accessToken && !checkJWTExpired(auth.accessToken)) {
      return auth.accessToken;
    } else if (isRefreshing) {
      // In the case where there are multiple requests that need new tokens, we only refresh them for the first request. The others will not have the `authorization` header, fail and be retried in the `onError` link
      return null;
    } else if (auth.refreshToken) {
      isRefreshing = true;
      const newTokens = await createNewTokens(auth.refreshToken);
      setAuth(newTokens);
      resolvePendingRequests();
      isRefreshing = false;
      return newTokens?.accessToken ?? null;
    }
    return null;
  }
  const authLink = setContext(async (_, { headers }) => {
    const authorization = await getFreshAccessToken();
    if (authorization != null) {
      headers ||= {};
      headers.authorization = authorization;
    }
    return { headers };
  });

  const uploadLink = createUploadLink({
    uri: API_URL,
  });

  const batchLink = new BatchHttpLink({
    uri: API_URL,
    batchMax: 5,
    batchInterval: 20,
  });

  const containsMutation = (operation: Operation) => {
    return !!operation.query.definitions.find(
      (it) => "operation" in it && it.operation === "mutation",
    );
  };

  const mediatorLink = ApolloLink.split(
    containsMutation,
    uploadLink,
    batchLink,
  );

  // TOKEN REFRESH STRATEGY
  // In `authLink` we check if we the accessToken seems expired and if we have the refreshToken we request a new pair.
  // This is inperfect, because the expiration check relies on system clock and the session may have been invalidated remotely.
  // For this reason in `errorLink` we check for the error associated with an invalid session and refresh the tokens if we can.
  const client = new ApolloClient({
    link: from([errorLink, authLink, mediatorLink]),
    cache: new InMemoryCache(CACHE_CONFIG),
    connectToDevTools: process.env.NODE_ENV === "development",
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-and-network",
      },
    },
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
