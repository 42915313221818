import { format } from "date-fns";
import React from "react";
import { Calendar as CalendarIcon } from "react-feather";
import { cn } from "utils/tailwind-merge";

import { Calendar } from "../Calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../Popover";

type DatePickerProps = {
  error?: boolean;
  onChange: (day: Date | undefined) => void;
  placeholder?: string;
  value?: Date;
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "value" | "onChange">;

const DatePicker = React.forwardRef<HTMLButtonElement, DatePickerProps>(
  ({ className, error, onChange, placeholder, value, ...props }) => {
    const currentYear = new Date().getFullYear();

    return (
      <>
        <Popover>
          <PopoverTrigger asChild>
            <button
              className={cn(
                "font-suisse flex w-full items-center justify-between rounded-lg border px-3 py-2 text-left font-normal text-new-neutral60",
                !value && "text-new-neutral50",
                error
                  ? "border-new-danger40 focus:outline-new-danger40 "
                  : "border-new-neutral30 focus:outline-new-neutral90",
                className,
              )}
              {...props}
            >
              {value ? (
                format(value, "PPP")
              ) : (
                <span className="text-new-neutral50">
                  {placeholder ?? "Pick a date"}
                </span>
              )}
              <CalendarIcon className="h-4 w-4 shrink-0 text-new-neutral50" />
            </button>
          </PopoverTrigger>
          <PopoverContent align="start" className="w-auto p-0">
            <Calendar
              captionLayout="dropdown-buttons"
              defaultMonth={value}
              fromYear={currentYear - 40}
              mode="single"
              selected={value}
              toYear={currentYear + 5}
              onSelect={onChange}
            />
          </PopoverContent>
        </Popover>
      </>
    );
  },
);
DatePicker.displayName = "DatePicker";

export default DatePicker;
