import { ChoiceInput, QuestionInput } from "apollo/types/graphql";
import { Button } from "components/new/Button";
import { QuizParams, contestAdminRoute } from "const/routes";
import { t } from "i18n";
import { useQuickToast } from "pages/Shared/hooks";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useEditQuiz, useQuizWithPoints } from "./hooks";

const Questions = () => {
  const navigate = useNavigate();
  const { quizId } = useParams<QuizParams>() as QuizParams;

  const { editQuiz, loading } = useEditQuiz();

  const { data } = useQuizWithPoints(quizId);

  const [questions, setQuestions] = useState<any[]>(
    data?.quiz.questions ?? [-1],
  );

  if (data?.quiz.questions && questions[0] == -1) {
    setQuestions(data?.quiz.questions);
  }

  const addQuestion = () => {
    setQuestions([
      ...questions,
      {
        id: Math.random(),
        text: "enter the question",
        choices: [
          {
            id: 1,
            text: "1",
          },
          {
            id: 2,
            text: "2",
          },
          {
            id: 3,
            text: "3",
          },
          {
            id: 4,
            text: "4",
          },
        ],
      },
    ]);
  };

  const deleteChoice = (questionId: number, choiceId: number) => {
    const newQuestions = questions.map((question: any) => {
      if (question.id === questionId) {
        return {
          ...question,
          choices: question.choices.filter(
            (choice: any) => choice.id !== choiceId,
          ),
        };
      }
      return question;
    });
    setQuestions(newQuestions);
  };

  const deleteQuestion = (questionId: number) => {
    const newQuestions = questions.filter(
      (question: any) => question.id !== questionId,
    );
    setQuestions(newQuestions);
  };

  const changeQuestionText = (questionId: number, text: string) => {
    const newQuestions = questions.map((question: any) => {
      if (question.id === questionId) {
        return {
          ...question,
          text,
        };
      }
      return question;
    });
    setQuestions(newQuestions);
  };

  const changeQuestionChoiceText = (
    questionId: number,
    choiceId: number,
    text: string,
  ) => {
    const newQuestions = questions.map((question: any) => {
      if (question.id === questionId) {
        return {
          ...question,
          choices: question.choices.map((choice: any) => {
            if (choice.id === choiceId) {
              return {
                ...choice,
                text,
              };
            }
            return choice;
          }),
        };
      }
      return question;
    });
    setQuestions(newQuestions);
  };

  const setAsTheCorrectAnswer = (questionId: number, choiceId: number) => {
    const newQuestions = questions.map((question: any) => {
      if (question.id === questionId) {
        return {
          ...question,
          choices: question.choices.map((choice: any) => {
            if (choice.id === choiceId) {
              return {
                ...choice,
                points: 1,
              };
            }
            return {
              ...choice,
              points: 0,
            };
          }),
        };
      }
      return question;
    });
    setQuestions(newQuestions);
  };

  const { errorToast, successToast } = useQuickToast();
  const getQuestionsInput = () =>
    questions.map((question: any) => {
      return {
        text: question.text as string,
        choices: question.choices.map((choice: any) => {
          return {
            text: choice.text,
            points: choice.points,
          };
        }) as ChoiceInput[],
      };
    });

  const quiz = data?.quiz;
  const saveQuiz = async (questions: QuestionInput[]) => {
    editQuiz({
      variables: {
        quizId,
        questions,
      },
    })
      .then(() => {
        successToast({ description: "tmam" });
        navigate(contestAdminRoute({ contestId: quiz?.contest.id }));
      })
      .catch(() => {
        errorToast();
      });
  };

  if (questions[0] === -1) {
    return <div>loading</div>;
  }
  return (
    <>
      {questions.map((question: any) => (
        <>
          <div className="w-full p-6" key={question.id}>
            <input
              className="mr-2 w-full text-right text-xl"
              type="text"
              value={question.text}
              onChange={(e) => changeQuestionText(question.id, e.target.value)}
            />
            <div className="mt-6 flex flex-col">
              {question.choices.map((choice: any) => (
                <>
                  <input
                    key={choice.id}
                    type="text"
                    value={choice.text}
                    onChange={(e) =>
                      changeQuestionChoiceText(
                        question.id,
                        choice.id,
                        e.target.value,
                      )
                    }
                  />
                  {!choice.points && (
                    <Button
                      btnStyle="transparent"
                      className="mt-5"
                      onClick={() =>
                        setAsTheCorrectAnswer(question.id, choice.id)
                      }
                    >
                      {t.quiz.admin.setCorrect}
                    </Button>
                  )}
                  <Button
                    btnStyle="transparent"
                    className="mt-5"
                    onClick={() => deleteChoice(question.id, choice.id)}
                  >
                    {t.quiz.admin.deleteChoice}
                  </Button>
                </>
              ))}
            </div>
            <Button
              btnStyle="transparent"
              className="mt-5"
              onClick={() => deleteQuestion(question.id)}
            >
              {t.quiz.admin.deleteQuestion}
            </Button>
          </div>
          <div className="mt-3 h-0.5 w-full bg-gray-100" />
        </>
      ))}
      <Button btnStyle="transparent" className="mt-5" onClick={addQuestion}>
        {t.quiz.admin.addQuestion}
      </Button>
      <Button
        className="mt-5"
        disabled={loading}
        onClick={() => saveQuiz(getQuestionsInput())}
      >
        {t.quiz.admin.saveQuiz}
      </Button>
    </>
  );
};

export default Questions;
