import { Button } from "components/new/Button";
import { RadioGroup, RadioGroupItem } from "components/new/RadioGroup";
import { QuizParams, contestRoute } from "const/routes";
import { t } from "i18n";
import { useQuickToast } from "pages/Shared/hooks";
import { useNavigate, useParams } from "react-router-dom";

import { useQuiz, useSubmitQuiz } from "./hooks";

const Questions = () => {
  const { quizId } = useParams<QuizParams>() as QuizParams;

  const { errorToast, successToast } = useQuickToast();
  const navigate = useNavigate();

  const { loading, submitQuiz } = useSubmitQuiz();

  const { data } = useQuiz(quizId);
  const quiz = data?.quiz;

  const solveQuiz = async (answers: string[]) => {
    submitQuiz({
      variables: {
        quizId,
        answers,
      },
    })
      .then(() => {
        successToast({ description: t.quiz.user.successSubmitQuiz });
        navigate(contestRoute({ contestId: quiz?.contest.id }), {
          replace: true,
        });
      })
      .catch(() => {
        errorToast();
      });
  };

  const answers: Record<string, string> = {};
  const onValueChange = (questionId: string, choice: string) => {
    answers[questionId] = choice;
  };

  if (quiz?.solved) {
    navigate(contestRoute({ contestId: quiz?.contest.id }), {
      replace: true,
    });
  }

  return (
    <>
      {quiz?.questions.map((question: any) => (
        <>
          <div className="w-full p-6" key={question.id}>
            <h1 className="mr-2 text-right text-xl">{question.text}</h1>
            <RadioGroup
              className="mt-6"
              dir="rtl"
              onValueChange={(value) => onValueChange(question.id, value)}
            >
              {question.choices.map((choice: any) => (
                <div
                  className="mt-3 flex flex-row items-center"
                  key={choice.id}
                >
                  <RadioGroupItem label="" value={choice.id} />
                  <h1 className="mr-2 text-right text-xl">{choice.text}</h1>
                </div>
              ))}
            </RadioGroup>
          </div>
          <div className="mt-3 h-0.5 w-full bg-gray-100" />
        </>
      ))}
      <Button
        className="mt-5"
        disabled={loading}
        onClick={() => solveQuiz(Object.values(answers))}
      >
        {t.quiz.user.submitQuiz}
      </Button>
    </>
  );
};

export default Questions;
