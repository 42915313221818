import React from "react";
import { classNames } from "utils/styles";

export enum ButtonVariant {
  Color = "color",
  Danger = "danger",
  Default = "default",
}

export type ButtonProps<V extends ButtonVariant> =
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    children: React.ReactNode | React.ReactNode[];
    loading?: boolean;
    variant?: V;
  };

// TODO(Jakub): add loading indicator

export type PrimaryButtonVariants =
  | ButtonVariant.Danger
  | ButtonVariant.Default;
export const PrimaryButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps<PrimaryButtonVariants>
>(function PrimaryButton(
  {
    children,
    className,
    disabled,
    loading,
    variant = ButtonVariant.Default,
    ...props
  },
  ref,
) {
  const variants: Record<PrimaryButtonVariants, string> = {
    [ButtonVariant.Default]:
      "outline-gray100 border-yellow600 bg-yellow600 hover:border-yellow700 hover:bg-yellow700 disabled:border-yellow200 disabled:bg-yellow200 text-primary900 disabled:text-primary300",
    [ButtonVariant.Danger]:
      "outline-error100 border-error600 bg-error600 hover:border-error700 hover:bg-error700 disabled:border-error200 disabled:bg-error200 text-white disabled:text-white",
  };

  return (
    <button
      {...props}
      className={classNames(
        "inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-lg border px-4 py-2 font-medium shadow-sm outline-4 focus:outline",
        variants[variant],
        className,
      )}
      disabled={disabled || loading}
      ref={ref}
    >
      {children}
    </button>
  );
});

export type SecondaryButtonVariants =
  | ButtonVariant.Danger
  | ButtonVariant.Color
  | ButtonVariant.Default;
export const SecondaryButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps<SecondaryButtonVariants>
>(function SecondaryButton(
  {
    children,
    className,
    disabled,
    loading,
    variant = ButtonVariant.Default,
    ...props
  },
  ref,
) {
  const variants: Record<SecondaryButtonVariants, string> = {
    [ButtonVariant.Default]:
      "outline-gray100 border-gray300 text-gray700 bg-white hover:bg-gray50 disabled:border-gray200 disabled:text-gray300",
    [ButtonVariant.Danger]:
      "outline-error100 border-error300 text-error700 hover:bg-error50 disabled:border-error200 disabled:text-error300",
    [ButtonVariant.Color]:
      "outline-primary100 border-primary600 bg-primary600 hover:border-primary700 hover:bg-primary700 disabled:border-primary200 disabled:bg-primary200 text-white disabled:text-white",
  };

  return (
    <button
      {...props}
      className={classNames(
        "inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-lg border px-4 py-2 font-medium  shadow-sm focus:outline focus:outline-4 disabled:shadow-none",
        variants[variant],
        className,
      )}
      disabled={disabled || loading}
      ref={ref}
    >
      {children}
    </button>
  );
});

export type TertiaryButtonVariants =
  | ButtonVariant.Danger
  | ButtonVariant.Color
  | ButtonVariant.Default;
export const TertiaryButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps<TertiaryButtonVariants>
>(function TertiaryButton(
  {
    children,
    className,
    disabled,
    loading,
    variant = ButtonVariant.Default,
    ...props
  },
  ref,
) {
  const variants: Record<TertiaryButtonVariants, string> = {
    [ButtonVariant.Default]:
      "text-gray500 hover:text-gray600 disabled:text-gray300",
    [ButtonVariant.Danger]:
      "text-error700 hover:text-error800 disabled:text-error300",
    [ButtonVariant.Color]:
      "text-primary700 hover:text-primary800 disabled:text-gray300",
  };

  return (
    <button
      {...props}
      className={classNames(
        "inline-flex items-center justify-center gap-2 whitespace-nowrap text-sm font-medium ",
        variants[variant],
        className,
      )}
      disabled={disabled || loading}
      ref={ref}
    >
      {children}
    </button>
  );
});
