import { useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { gql } from "apollo/types";
import { Button } from "components/new/Button";
import { Card } from "components/new/Card";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from "components/new/Form";
import { FormInput } from "components/new/FormInput";
import { Input } from "components/new/Input";
import { LinkButton } from "components/new/LinkButton";
import { PasswordMeter } from "components/new/PasswordMeter";
import ROUTES, { PasswordResetParams } from "const/routes";
import { t } from "i18n";
import React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuthDispatch } from "root/AuthProvider";
import { useSignOut } from "utils/auth";
import * as z from "zod";

const RESET_PASSWORD = gql(`
mutation ResetPassword($requestId: String!, $newPassword: String!) {
  resetPassword(requestId: $requestId, newPassword: $newPassword) {
    accessToken
    refreshToken
  }
}
`);

const formSchema = z
  .object({
    password: z.string().min(8, t.validation.password.minLength),
    confirm: z.string().min(1, t.validation.confirmPassword.required),
  })
  .refine((data) => data.password === data.confirm, {
    message: t.validation.confirmPassword.match,
    path: ["confirm"],
  });

const ResetPassword: React.FC = () => {
  const { requestId } = useParams<PasswordResetParams>() as PasswordResetParams;
  const setAuth = useAuthDispatch();
  const navigate = useNavigate();
  const signOut = useSignOut();
  const [resetPassword, { error, loading }] = useMutation(RESET_PASSWORD);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: "",
      confirm: "",
    },
  });

  const onSubmit = async ({ password }: z.infer<typeof formSchema>) => {
    // TODO(Jakub): an explicit signout would probably be better
    await signOut();
    const { data } = await resetPassword({
      variables: { newPassword: password, requestId },
    });
    const tokens = data?.resetPassword ?? null;
    if (tokens) {
      // TODO(Jakub): add checkbox for selecting persistance mode
      setAuth(tokens);
      navigate(ROUTES.HOME);
    }
  };

  return (
    <div className="font-suisse flex w-screen max-w-[440px] flex-col gap-8 sm:p-6">
      <div className="flex flex-col gap-6 text-2xl">
        <h1 className="font-medium">{t.screen.resetPassword.title}</h1>
      </div>
      <Card className="bg-new-neutral0">
        <Form
          className="flex flex-col gap-6 px-10 py-8"
          formContext={form}
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className="flex flex-col gap-5">
            <FormField
              control={form.control}
              name="password"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormControl>
                    <>
                      <Input
                        error={!!fieldState.error}
                        id="password"
                        label={t.screen.resetPassword.password.label}
                        placeholder={
                          t.screen.resetPassword.password.placeholder
                        }
                        type="password"
                        {...field}
                      />
                      <PasswordMeter password={field.value} />
                    </>
                  </FormControl>
                  {!fieldState.error && (
                    <FormDescription>
                      {t.screen.resetPassword.passwordHint}
                    </FormDescription>
                  )}
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormInput
              control={form.control}
              label={t.screen.resetPassword.confirm.label}
              name="confirm"
              placeholder={t.screen.resetPassword.confirm.placeholder}
              type="password"
            />
            {error && (
              <p className="text-sm font-medium text-new-danger60">
                {error.message}
              </p>
            )}
          </div>
          <div className="flex flex-col gap-4">
            <Button disabled={loading} type="submit">
              {t.screen.resetPassword.submit}
            </Button>
            <Link className="flex justify-center" to={ROUTES.SIGN_IN}>
              <LinkButton>{t.screen.resetPassword.cancel}</LinkButton>
            </Link>
          </div>
        </Form>
      </Card>
      <p>
        <span className="text-sm font-normal text-new-neutral60">
          {t.screen.forgotPassword.needHelp}{" "}
        </span>
        <a href={`mailto:${t.common.helpEmail}`}>
          <LinkButton>{t.common.helpEmail}</LinkButton>
        </a>
      </p>
    </div>
  );
};

export default ResetPassword;
