import React, { useEffect } from "react";

const GoogleAd: React.FC = () => {
  useEffect(() => {
    const scriptElement = document.querySelector(
      'script[src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6466794637567734"]',
    );

    const handleScriptLoad = () => {
      try {
        if (window.adsbygoogle) {
          console.log("pushing ads ");
          adsbygoogle.push({});
        } else {
          scriptElement.addEventListener("load", handleScriptLoad);
          console.log(
            "waiting until adsense lib is loaded...This prevents adsbygoogle is not defined error",
          );
        }
      } catch (err) {
        console.log(
          "error in adsense - This prevents ad already exists error",
          err,
        );
      }
    };

    handleScriptLoad();

    return () => {
      if (scriptElement) {
        scriptElement.removeEventListener("load", handleScriptLoad);
      }
    };
  });

  return (
    <div className="w-full">
      <ins
        className="adsbygoogle"
        data-ad-client="ca-pub-6466794637567734"
        data-ad-format="auto"
        data-ad-slot="1365698737"
        data-full-width-responsive="true"
        style={{ display: "block" }}
      ></ins>
    </div>
  );
};

export default GoogleAd;
