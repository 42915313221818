import { ApolloError, useMutation } from "@apollo/client";
import { gql } from "apollo/types";
import { Box } from "components/Box";
import ROUTES from "const/routes";
import { t } from "i18n";
import { useQuickToast } from "pages/Shared/hooks";
import { memo, useEffect } from "react";
import { Loader } from "react-feather";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAuthDispatch } from "root/AuthProvider";
import { parseOAuthState, useSignOut } from "utils/auth";
import {
  AuthLocationState,
  authLocationStateReset,
} from "utils/authLocationState";

let requested = false;

export const GoogleRedirect = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const signOut = useSignOut();
  const setAuth = useAuthDispatch();
  const { errorToast } = useQuickToast();

  const [signInWithGoogle] = useMutation(
    gql(`
  mutation LoginUserWithGoogle($token: String!) {
    loginUserWithGoogleAuthToken(token: $token) {
      accessToken
      refreshToken
    }
  }
`),
  );

  const [registerWithGoogle] = useMutation(
    gql(`
  mutation RegisterUserWithGoogle($token: String!) {
    registerUserWithGoogleAuthToken(token: $token) {
      accessToken
      refreshToken
    }
  }
`),
  );

  async function handleGoogleLogin(
    token: string,
    authLocationState: AuthLocationState,
  ) {
    if (requested) return;
    try {
      const route = authLocationState.from ?? ROUTES.HOME;
      requested = true;
      await signOut();
      const { data } = await signInWithGoogle({
        variables: { token },
      });
      const tokens = data?.loginUserWithGoogleAuthToken ?? null;
      if (tokens != null) {
        setAuth(tokens);
        authLocationStateReset();
        navigate(route);
      }
    } catch (err) {
      if (err instanceof ApolloError) {
        errorToast({ description: err.message });
      }
      navigate(ROUTES.SIGN_IN);
    }
  }

  async function handleGoogleSignUp(
    token: string,
    authLocationState: AuthLocationState,
  ) {
    if (requested) return;
    try {
      const route = authLocationState.from ?? ROUTES.HOME;
      requested = true;
      await signOut();
      const { data } = await registerWithGoogle({
        variables: {
          token,
        },
      });
      const tokens = data?.registerUserWithGoogleAuthToken ?? null;
      if (tokens != null) {
        setAuth(tokens);
        authLocationStateReset();
        navigate(route);
      }
    } catch (err) {
      if (err instanceof ApolloError) {
        errorToast({ description: err.message });
      }
      navigate(ROUTES.SIGN_UP);
    }
  }

  function handleRedirect() {
    const token = params.get("code");
    if (typeof token !== "string" || token.length === 0) {
      console.error("Invalid token");
      return;
    }
    const oAuthState = parseOAuthState(params.get("state"));
    if (oAuthState instanceof Error) {
      console.error(oAuthState);
      return;
    }
    if (oAuthState.action === "signin") {
      handleGoogleLogin(token, oAuthState.authLocationState);
    } else if (oAuthState.action === "signup") {
      handleGoogleSignUp(token, oAuthState.authLocationState);
    }
  }

  useEffect(handleRedirect, []);

  return (
    <div className="max-w-md p-6 sm:px-0">
      <div className="sm:px-6">
        <Link to={ROUTES.HOME}>
          <img
            alt="landing"
            className="m-2 h-10 w-10 rounded-full"
            src="/favicon.png"
          />
        </Link>
      </div>
      <Box className="my-6 sm:rounded-none">
        <Loader size="200" style={{ margin: "0 auto" }} />
      </Box>
      <div className="flex flex-row items-center justify-between gap-4 px-4 text-sm text-gray500 sm:flex-col sm:px-6">
        <p> {t.common.tagline}</p>
      </div>
    </div>
  );
};
export default memo(GoogleRedirect);
