import * as ToastPrimitives from "@radix-ui/react-toast";
import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";
import { AlertTriangle, Bell, Check, X } from "react-feather";

import { cn } from "utils/tailwind-merge";

import { FeaturedIcon } from "../FeaturedIcon";

const ToastProvider = ToastPrimitives.Provider;

const ToastViewport = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Viewport>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Viewport
    className={cn(
      "fixed left-0 right-0 top-20 z-[100] flex max-h-screen w-full flex-col gap-y-2 p-4",
      className,
    )}
    ref={ref}
    {...props}
  />
));
ToastViewport.displayName = ToastPrimitives.Viewport.displayName;

const Toast = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Root>
>(({ className, ...props }, ref) => {
  return (
    <ToastPrimitives.Root
      className={cn(
        "group pointer-events-auto relative mx-auto flex w-full max-w-[327px] items-center space-x-2 overflow-hidden rounded-lg border bg-new-neutral0 p-4 text-new-neutral70 shadow-lg transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full",
        className,
      )}
      ref={ref}
      {...props}
    />
  );
});
Toast.displayName = ToastPrimitives.Root.displayName;

const ToastClose = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Close>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Close>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Close
    className={cn(
      "absolute right-2 top-2 rounded-md p-1 text-new-neutral50 opacity-0 transition-opacity hover:text-new-neutral70 focus:text-new-neutral70 focus:outline-none focus:ring-2 group-hover:opacity-100 group-[.destructive]:text-red-300 group-[.destructive]:hover:text-red-50 group-[.destructive]:focus:ring-red-400 group-[.destructive]:focus:ring-offset-red-600",
      className,
    )}
    ref={ref}
    toast-close=""
    {...props}
  >
    <X className="h-4 w-4" />
  </ToastPrimitives.Close>
));
ToastClose.displayName = ToastPrimitives.Close.displayName;

const ToastTitle = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Title>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Title>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Title
    className={cn("text-md ", className)}
    ref={ref}
    {...props}
  />
));
ToastTitle.displayName = ToastPrimitives.Title.displayName;

const toastIconVariants = cva("h-8 w-8", {
  variants: {
    variant: {
      success: "text-new-success60 bg-new-success0",
      warning: "text-new-warning60 bg-new-warning0",
      danger: "text-new-danger60 bg-new-danger0",
      info: "text-new-neutral60 bg-new-neutral30",
    },
  },
  defaultVariants: {
    variant: "info",
  },
});

const ToastIcon: React.FC<VariantProps<typeof toastIconVariants>> = ({
  variant,
}) => {
  return (
    <FeaturedIcon className={toastIconVariants({ variant })}>
      {(!variant || variant === "info") && <Bell />}
      {variant === "success" && <Check />}
      {(variant === "danger" || variant === "warning") && <AlertTriangle />}
    </FeaturedIcon>
  );
};

type ToastProps = React.ComponentPropsWithoutRef<typeof Toast>;

export {
  type ToastProps,
  ToastProvider,
  ToastViewport,
  Toast,
  ToastTitle,
  ToastClose,
  ToastIcon,
};
