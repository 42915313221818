import { useMutation, useQuery } from "@apollo/client";
import { gql } from "apollo/types";

const QUIZ_WITH_POINTS = gql(`
query QuizWithPoints($quizId: String!) {
  quiz(quizId: $quizId) {
    id
    title
    description
    contest {
      id
      title
    }
    questions {
      id
      text
      choices {
        id
        text
        points
      }
    }
  }
}
`);

const EDIT_QUIZ = gql(`
mutation EditQuiz($quizId: String!, $questions: [QuestionInput!]!) {
  editQuiz(quizId: $quizId, questions: $questions) {
    id
  }
}
`);

export const useEditQuiz = () => {
  const [editQuiz, { loading }] = useMutation(EDIT_QUIZ);

  return {
    editQuiz,
    loading,
  };
};

export const useQuizWithPoints = (quizId: string) => {
  const { data } = useQuery(QUIZ_WITH_POINTS, {
    variables: { quizId },
  });

  return {
    data,
  };
};
