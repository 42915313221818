import Contests from "./Contests";
import Heading from "./Header";

const AdminHomePage = () => {
  return (
    <div className="m-auto block flex max-w-4xl flex-col items-center pt-5">
      <Heading />
      <Contests />
    </div>
  );
};

export default AdminHomePage;
