import { ControllerProps, FieldPath, FieldValues } from "react-hook-form";

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from "../Form";
import { Input } from "../Input";
import { Label } from "../Label";
import { Textarea } from "../Textarea";

const FormInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  className,
  control,
  hint,
  label,
  name,
  ...props
}: Pick<ControllerProps<TFieldValues, TName>, "control" | "name"> & {
  className?: string;
  "data-e2e-id"?: string;
  disabled?: boolean;
  hint?: string;
  label?: string;
  placeholder?: string;
  type?: string;
}) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <FormItem className={className}>
          <Label htmlFor={props["data-e2e-id"]}>{label}</Label>
          <FormControl>
            {props.type === "textarea"
              ? textarea(props, field, fieldState)
              : input(props, field, fieldState)}
          </FormControl>
          {hint && <FormDescription>{hint}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

const input = (props: any, field: any, fieldState: any) => (
  <Input
    data-e2e-id={props["data-e2e-id"]}
    disabled={props.disabled}
    error={!!fieldState.error}
    id={props["data-e2e-id"]}
    placeholder={props.placeholder}
    type={props.type}
    {...field}
  />
);

const textarea = (props: any, field: any, fieldState: any) => (
  <Textarea
    data-e2e-id={props["data-e2e-id"]}
    error={!!fieldState.error}
    id={props["data-e2e-id"]}
    placeholder={props.placeholder}
    type={props.type}
    {...field}
  />
);

export { FormInput };
