import GoogleAd from "components/new/GoogleAd";

import Contests from "./Contests";
import Heading from "./Header";

const HomePage = () => {
  return (
    <div className="m-auto block flex max-w-4xl flex-col items-center pt-5">
      <Heading />
      <Contests />
      <GoogleAd />
    </div>
  );
};

export default HomePage;
