/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Date custom scalar type */
  Date: { input: any; output: any };
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: string; output: string };
  /** A field whose value is a valid Decimal which safely allows mathematical operations on numbers */
  Decimal: { input: string; output: string };
};

export type Choice = {
  __typename?: "Choice";
  id: Scalars["ID"]["output"];
  points: Scalars["Float"]["output"];
  question: Question;
  text: Scalars["String"]["output"];
  users: Array<User>;
};

export type ChoiceInput = {
  points: Scalars["Float"]["input"];
  text: Scalars["String"]["input"];
};

export type Contest = {
  __typename?: "Contest";
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  imageUrl: Scalars["String"]["output"];
  quizzes: Array<Quiz>;
  status: ContestStatus;
  title: Scalars["String"]["output"];
};

export type ContestData = {
  __typename?: "ContestData";
  certificate?: Maybe<Scalars["String"]["output"]>;
  contest: Contest;
};

export enum ContestStatus {
  Active = "ACTIVE",
  Hidden = "HIDDEN",
  Past = "PAST",
  Upcoming = "UPCOMING",
}

/** Pending email verification request */
export type EmailVerificationEntity = {
  __typename?: "EmailVerificationEntity";
  /** Email waiting to be verified */
  email: Scalars["String"]["output"];
  /** ID sent in a verification email used to confirm address ownership */
  id: Scalars["String"]["output"];
  /** Date by which the request will expire */
  validTill: Scalars["DateTime"]["output"];
};

export type Mutation = {
  __typename?: "Mutation";
  createNewTokens: UserAuthResponse;
  deleteCurrentUserSession: Scalars["Boolean"]["output"];
  editQuiz: Quiz;
  loginUser: UserAuthResponse;
  loginUserWithGoogleAuthToken: UserAuthResponse;
  registerUser: UserAuthResponse;
  registerUserWithGoogleAuthToken: UserAuthResponse;
  requestEmailUpdate: Scalars["Boolean"]["output"];
  requestPasswordReset: Scalars["Boolean"]["output"];
  resendVerificationEmail: Scalars["Boolean"]["output"];
  resetPassword: UserAuthResponse;
  submitQuiz: User;
  updatePassword: Scalars["Boolean"]["output"];
  updateProfileDetails: User;
  verifyEmail: User;
};

export type MutationCreateNewTokensArgs = {
  refreshToken: Scalars["String"]["input"];
};

export type MutationEditQuizArgs = {
  questions: Array<QuestionInput>;
  quizId: Scalars["String"]["input"];
};

export type MutationLoginUserArgs = {
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type MutationLoginUserWithGoogleAuthTokenArgs = {
  token: Scalars["String"]["input"];
};

export type MutationRegisterUserArgs = {
  church?: InputMaybe<Scalars["String"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["Date"]["input"]>;
  email: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type MutationRegisterUserWithGoogleAuthTokenArgs = {
  token: Scalars["String"]["input"];
};

export type MutationRequestEmailUpdateArgs = {
  newEmail: Scalars["String"]["input"];
};

export type MutationRequestPasswordResetArgs = {
  email: Scalars["String"]["input"];
};

export type MutationResetPasswordArgs = {
  newPassword: Scalars["String"]["input"];
  requestId: Scalars["String"]["input"];
};

export type MutationSubmitQuizArgs = {
  answers: Array<Scalars["String"]["input"]>;
  quizId: Scalars["String"]["input"];
};

export type MutationUpdatePasswordArgs = {
  currentPassword: Scalars["String"]["input"];
  newPassword: Scalars["String"]["input"];
};

export type MutationUpdateProfileDetailsArgs = {
  church?: InputMaybe<Scalars["String"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["Date"]["input"]>;
  name: Scalars["String"]["input"];
};

export type MutationVerifyEmailArgs = {
  verificationId: Scalars["String"]["input"];
};

export type Query = {
  __typename?: "Query";
  adminContests: Array<Contest>;
  contest: ContestData;
  contests: Array<Contest>;
  quiz: Quiz;
  user: User;
};

export type QueryContestArgs = {
  contestId: Scalars["String"]["input"];
};

export type QueryQuizArgs = {
  quizId: Scalars["String"]["input"];
};

export type Question = {
  __typename?: "Question";
  choices: Array<Choice>;
  id: Scalars["ID"]["output"];
  quiz: Quiz;
  text: Scalars["String"]["output"];
};

export type QuestionInput = {
  choices: Array<ChoiceInput>;
  text: Scalars["String"]["input"];
};

export type Quiz = {
  __typename?: "Quiz";
  contest: Contest;
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isPublished: Scalars["Boolean"]["output"];
  publishDate: Scalars["DateTime"]["output"];
  questions: Array<Question>;
  solved: Scalars["Boolean"]["output"];
  title: Scalars["String"]["output"];
};

export enum Role {
  ContestAdmin = "ContestAdmin",
  Customer = "Customer",
  Root = "Root",
}

export type User = {
  __typename?: "User";
  choices: Array<Choice>;
  church?: Maybe<Scalars["String"]["output"]>;
  dateOfBirth?: Maybe<Scalars["DateTime"]["output"]>;
  email: Scalars["String"]["output"];
  /** Email changes pending address verification */
  emailVerification?: Maybe<EmailVerificationEntity>;
  id: Scalars["ID"]["output"];
  isVerified: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  passwordUpdatedAt: Scalars["DateTime"]["output"];
  roles: Array<UserRole>;
  sessions: Array<UserSession>;
  solvedContests: Array<UserContest>;
};

export type UserAuthResponse = {
  __typename?: "UserAuthResponse";
  accessToken?: Maybe<Scalars["String"]["output"]>;
  isMfaRequired: Scalars["Boolean"]["output"];
  refreshToken?: Maybe<Scalars["String"]["output"]>;
  userId: Scalars["String"]["output"];
};

export type UserContest = {
  __typename?: "UserContest";
  certificate: Scalars["String"]["output"];
  contest: Contest;
  id: Scalars["ID"]["output"];
};

export type UserRole = {
  __typename?: "UserRole";
  contest?: Maybe<Contest>;
  contestId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  role: Role;
};

export type UserSession = {
  __typename?: "UserSession";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  ipAddress: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type VerifyEmailMutationVariables = Exact<{
  verificationId: Scalars["String"]["input"];
}>;

export type VerifyEmailMutation = {
  __typename?: "Mutation";
  verifyEmail: { __typename?: "User"; email: string };
};

export type ResendVerificationEmailMutationVariables = Exact<{
  [key: string]: never;
}>;

export type ResendVerificationEmailMutation = {
  __typename?: "Mutation";
  resendVerificationEmail: boolean;
};

export type DeleteCurrentUserSessionMutationVariables = Exact<{
  [key: string]: never;
}>;

export type DeleteCurrentUserSessionMutation = {
  __typename?: "Mutation";
  deleteCurrentUserSession: boolean;
};

export type GetUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserQuery = {
  __typename?: "Query";
  user: {
    __typename?: "User";
    id: string;
    email: string;
    name: string;
    church?: string | null;
    dateOfBirth?: string | null;
    roles: Array<{
      __typename?: "UserRole";
      role: Role;
      contest?: { __typename?: "Contest"; id: string } | null;
    }>;
  };
};

export type ContestAdminQueryVariables = Exact<{
  contestId: Scalars["String"]["input"];
}>;

export type ContestAdminQuery = {
  __typename?: "Query";
  contest: {
    __typename?: "ContestData";
    contest: {
      __typename?: "Contest";
      id: string;
      title: string;
      description: string;
      imageUrl: string;
      quizzes: Array<{
        __typename?: "Quiz";
        id: string;
        title: string;
        description: string;
        isPublished: boolean;
      }>;
    };
  };
};

export type AdminContestsQueryVariables = Exact<{ [key: string]: never }>;

export type AdminContestsQuery = {
  __typename?: "Query";
  adminContests: Array<{
    __typename?: "Contest";
    id: string;
    title: string;
    description: string;
    imageUrl: string;
  }>;
};

export type ContestQueryVariables = Exact<{
  contestId: Scalars["String"]["input"];
}>;

export type ContestQuery = {
  __typename?: "Query";
  contest: {
    __typename?: "ContestData";
    certificate?: string | null;
    contest: {
      __typename?: "Contest";
      id: string;
      title: string;
      description: string;
      imageUrl: string;
      quizzes: Array<{
        __typename?: "Quiz";
        id: string;
        title: string;
        description: string;
        solved: boolean;
        isPublished: boolean;
      }>;
    };
  };
};

export type QuizWithPointsQueryVariables = Exact<{
  quizId: Scalars["String"]["input"];
}>;

export type QuizWithPointsQuery = {
  __typename?: "Query";
  quiz: {
    __typename?: "Quiz";
    id: string;
    title: string;
    description: string;
    contest: { __typename?: "Contest"; id: string; title: string };
    questions: Array<{
      __typename?: "Question";
      id: string;
      text: string;
      choices: Array<{
        __typename?: "Choice";
        id: string;
        text: string;
        points: number;
      }>;
    }>;
  };
};

export type EditQuizMutationVariables = Exact<{
  quizId: Scalars["String"]["input"];
  questions: Array<QuestionInput> | QuestionInput;
}>;

export type EditQuizMutation = {
  __typename?: "Mutation";
  editQuiz: { __typename?: "Quiz"; id: string };
};

export type OldRequestPasswordResetMutationVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type OldRequestPasswordResetMutation = {
  __typename?: "Mutation";
  requestPasswordReset: boolean;
};

export type LoginUserWithGoogleMutationVariables = Exact<{
  token: Scalars["String"]["input"];
}>;

export type LoginUserWithGoogleMutation = {
  __typename?: "Mutation";
  loginUserWithGoogleAuthToken: {
    __typename?: "UserAuthResponse";
    accessToken?: string | null;
    refreshToken?: string | null;
  };
};

export type RegisterUserWithGoogleMutationVariables = Exact<{
  token: Scalars["String"]["input"];
}>;

export type RegisterUserWithGoogleMutation = {
  __typename?: "Mutation";
  registerUserWithGoogleAuthToken: {
    __typename?: "UserAuthResponse";
    accessToken?: string | null;
    refreshToken?: string | null;
  };
};

export type ContestsQueryVariables = Exact<{ [key: string]: never }>;

export type ContestsQuery = {
  __typename?: "Query";
  contests: Array<{
    __typename?: "Contest";
    id: string;
    title: string;
    description: string;
    imageUrl: string;
    status: ContestStatus;
  }>;
};

export type QuizQueryVariables = Exact<{
  quizId: Scalars["String"]["input"];
}>;

export type QuizQuery = {
  __typename?: "Query";
  quiz: {
    __typename?: "Quiz";
    id: string;
    title: string;
    solved: boolean;
    contest: { __typename?: "Contest"; id: string; title: string };
    questions: Array<{
      __typename?: "Question";
      text: string;
      id: string;
      choices: Array<{ __typename?: "Choice"; id: string; text: string }>;
    }>;
  };
};

export type SubmitQuizMutationVariables = Exact<{
  quizId: Scalars["String"]["input"];
  answers: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type SubmitQuizMutation = {
  __typename?: "Mutation";
  submitQuiz: {
    __typename?: "User";
    name: string;
    choices: Array<{ __typename?: "Choice"; id: string; text: string }>;
  };
};

export type OldResetPasswordMutationVariables = Exact<{
  requestId: Scalars["String"]["input"];
  newPassword: Scalars["String"]["input"];
}>;

export type OldResetPasswordMutation = {
  __typename?: "Mutation";
  resetPassword: {
    __typename?: "UserAuthResponse";
    accessToken?: string | null;
    refreshToken?: string | null;
  };
};

export type RequestPasswordResetMutationVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type RequestPasswordResetMutation = {
  __typename?: "Mutation";
  requestPasswordReset: boolean;
};

export type ResetPasswordMutationVariables = Exact<{
  requestId: Scalars["String"]["input"];
  newPassword: Scalars["String"]["input"];
}>;

export type ResetPasswordMutation = {
  __typename?: "Mutation";
  resetPassword: {
    __typename?: "UserAuthResponse";
    accessToken?: string | null;
    refreshToken?: string | null;
  };
};

export type LoginUserMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
}>;

export type LoginUserMutation = {
  __typename?: "Mutation";
  loginUser: {
    __typename?: "UserAuthResponse";
    accessToken?: string | null;
    refreshToken?: string | null;
  };
};

export type RegisterUserMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  church?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  dateOfBirth?: InputMaybe<Scalars["Date"]["input"]>;
}>;

export type RegisterUserMutation = {
  __typename?: "Mutation";
  registerUser: {
    __typename?: "UserAuthResponse";
    accessToken?: string | null;
    refreshToken?: string | null;
  };
};

export type UpdateProfileDetailsMutationVariables = Exact<{
  church?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  dateOfBirth?: InputMaybe<Scalars["Date"]["input"]>;
}>;

export type UpdateProfileDetailsMutation = {
  __typename?: "Mutation";
  updateProfileDetails: {
    __typename?: "User";
    name: string;
    church?: string | null;
    dateOfBirth?: string | null;
  };
};

export type CreateNewTokensMutationVariables = Exact<{
  refreshToken: Scalars["String"]["input"];
}>;

export type CreateNewTokensMutation = {
  __typename?: "Mutation";
  createNewTokens: {
    __typename?: "UserAuthResponse";
    accessToken?: string | null;
    refreshToken?: string | null;
  };
};

export const VerifyEmailDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "VerifyEmail" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "verificationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "verifyEmail" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "verificationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "verificationId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const ResendVerificationEmailDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ResendVerificationEmail" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "resendVerificationEmail" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResendVerificationEmailMutation,
  ResendVerificationEmailMutationVariables
>;
export const DeleteCurrentUserSessionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteCurrentUserSession" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteCurrentUserSession" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCurrentUserSessionMutation,
  DeleteCurrentUserSessionMutationVariables
>;
export const GetUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetUser" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "church" } },
                { kind: "Field", name: { kind: "Name", value: "dateOfBirth" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "roles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "role" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "contest" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserQuery, GetUserQueryVariables>;
export const ContestAdminDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ContestAdmin" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "contestId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "contest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "contestId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "contestId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contest" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "imageUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quizzes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isPublished" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContestAdminQuery, ContestAdminQueryVariables>;
export const AdminContestsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AdminContests" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "adminContests" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "imageUrl" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminContestsQuery, AdminContestsQueryVariables>;
export const ContestDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Contest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "contestId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "contest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "contestId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "contestId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contest" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "imageUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quizzes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "solved" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isPublished" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "certificate" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContestQuery, ContestQueryVariables>;
export const QuizWithPointsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "QuizWithPoints" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "quizId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "quiz" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "quizId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "quizId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contest" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "questions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "choices" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "text" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "points" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuizWithPointsQuery, QuizWithPointsQueryVariables>;
export const EditQuizDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "EditQuiz" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "quizId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "questions" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "QuestionInput" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "editQuiz" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "quizId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "quizId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "questions" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "questions" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditQuizMutation, EditQuizMutationVariables>;
export const OldRequestPasswordResetDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "OldRequestPasswordReset" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "requestPasswordReset" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OldRequestPasswordResetMutation,
  OldRequestPasswordResetMutationVariables
>;
export const LoginUserWithGoogleDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "LoginUserWithGoogle" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "token" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "loginUserWithGoogleAuthToken" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "token" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "token" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "accessToken" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "refreshToken" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoginUserWithGoogleMutation,
  LoginUserWithGoogleMutationVariables
>;
export const RegisterUserWithGoogleDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RegisterUserWithGoogle" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "token" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "registerUserWithGoogleAuthToken" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "token" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "token" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "accessToken" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "refreshToken" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RegisterUserWithGoogleMutation,
  RegisterUserWithGoogleMutationVariables
>;
export const ContestsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Contests" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "contests" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "imageUrl" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContestsQuery, ContestsQueryVariables>;
export const QuizDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Quiz" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "quizId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "quiz" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "quizId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "quizId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "solved" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contest" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "questions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "choices" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "text" },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuizQuery, QuizQueryVariables>;
export const SubmitQuizDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SubmitQuiz" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "quizId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "answers" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "String" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "submitQuiz" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "quizId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "quizId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "answers" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "answers" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "choices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubmitQuizMutation, SubmitQuizMutationVariables>;
export const OldResetPasswordDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "OldResetPassword" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "requestId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "newPassword" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "resetPassword" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "requestId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "requestId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "newPassword" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "newPassword" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "accessToken" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "refreshToken" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OldResetPasswordMutation,
  OldResetPasswordMutationVariables
>;
export const RequestPasswordResetDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RequestPasswordReset" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "requestPasswordReset" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables
>;
export const ResetPasswordDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ResetPassword" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "requestId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "newPassword" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "resetPassword" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "requestId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "requestId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "newPassword" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "newPassword" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "accessToken" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "refreshToken" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const LoginUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "LoginUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "password" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "loginUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "password" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "password" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "accessToken" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "refreshToken" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoginUserMutation, LoginUserMutationVariables>;
export const RegisterUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RegisterUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "password" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "church" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dateOfBirth" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "registerUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "password" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "password" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "church" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "church" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dateOfBirth" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dateOfBirth" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "accessToken" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "refreshToken" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RegisterUserMutation,
  RegisterUserMutationVariables
>;
export const UpdateProfileDetailsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateProfileDetails" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "church" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dateOfBirth" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateProfileDetails" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "church" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "church" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dateOfBirth" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dateOfBirth" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "church" } },
                { kind: "Field", name: { kind: "Name", value: "dateOfBirth" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProfileDetailsMutation,
  UpdateProfileDetailsMutationVariables
>;
export const CreateNewTokensDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateNewTokens" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "refreshToken" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createNewTokens" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "refreshToken" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "refreshToken" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "accessToken" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "refreshToken" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateNewTokensMutation,
  CreateNewTokensMutationVariables
>;
