import { ar } from "./ar";
import { debug } from "./debug";

export { format } from "./format";

const translations = {
  debug,
  ar,
};

type Languages = keyof typeof translations;

export let t = translations.ar;

/**
 * Updates translation strings
 *
 * **DOES NOT CAUSE RERENDER!**
 * Make sure you trigger a rerender of all translated components after changing the translation language.
 */
export function setI18n(lang: Languages): void {
  t = translations[lang];
}
