import {
  Toast,
  ToastClose,
  ToastIcon,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "./toast";
import { useToast } from "./use-toast";

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(function ({ id, message, variant, ...props }) {
        return (
          <Toast key={id} {...props}>
            <ToastIcon variant={variant} />
            <div>{message && <ToastTitle>{message}</ToastTitle>}</div>
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
