import { useQuery } from "@apollo/client";
import { gql } from "apollo/types";
import { Contest } from "apollo/types/graphql";
import { contestAdminRoute } from "const/routes";
import { Link } from "react-router-dom";

const ADMIN_CONTESTS = gql(`
query AdminContests {
  adminContests {
    id
    title
    description
    imageUrl
  }
}
`);

const Contests = () => {
  const { data } = useQuery(ADMIN_CONTESTS);
  const contests = data?.adminContests;

  return (
    <>
      {contests?.map((contest: Partial<Contest>) => (
        <div className="flex w-full flex-col items-center" key={contest.id}>
          <Link
            className="relative mt-5 w-11/12"
            to={contestAdminRoute({ contestId: contest.id })}
          >
            <div>
              <img
                alt="landing"
                className="h-50 mt-5 w-full rounded-lg object-cover"
                src={contest.imageUrl}
              />
              <div className="absolute bottom-2 right-2 text-white">
                <h1 className="text-bold text-right text-2xl">
                  {contest.title}
                </h1>
                <h1 className="text-right text-xl">{contest.description}</h1>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </>
  );
};

export default Contests;
