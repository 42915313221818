import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ApolloClientProvider } from "root/ApolloClientProvider";
import AppErrorBoundary from "root/AppErrorBoundary";
import { AuthProvider } from "root/AuthProvider";

import reportWebVitals from "./reportWebVitals";
import AppRoutes from "./root/AppRoutes";
import theme from "./theme";

import "./index.css";

// Initialize Firebase
const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

// Set dir="rtl" on <html> element to enable right-to-left text direction
document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");

const customTheme = extendTheme(theme);

root.render(
  <React.StrictMode>
    <ChakraProvider portalZIndex={1000} theme={customTheme}>
      <AuthProvider>
        <ApolloClientProvider>
          <BrowserRouter>
            <AppErrorBoundary>
              <AppRoutes />
            </AppErrorBoundary>
          </BrowserRouter>
        </ApolloClientProvider>
      </AuthProvider>
    </ChakraProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
