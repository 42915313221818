import React from "react";
import { cn } from "utils/tailwind-merge";

// This is only for types, do not import any actual values from the library
import type { zxcvbn } from "zxcvbn-typescript";
type Zxcvbn = typeof zxcvbn;

export type PasswordMeterProps = {
  password: string;
};

export const PasswordMeter: React.FC<PasswordMeterProps> = ({ password }) => {
  const [test, setTest] = React.useState<Zxcvbn | null>(null);

  React.useEffect(() => {
    import("zxcvbn-typescript")
      .then((pkg) => {
        // @ts-expect-error Imports are weird
        const test = pkg.default.default;
        // We need to use the function mode of useState, otherwise it would interpret the test function as a state action
        setTest(() => test);
      })
      .catch((err) => {
        console.warn(err);
      });
  }, []);

  const testedResult = test?.(password);

  let barBgClasses =
    "[&::-moz-progress-bar]:bg-new-danger60 [&::-webkit-progress-value]:bg-new-danger60";
  if (testedResult) {
    if (testedResult.score > 2 && testedResult?.score < 4) {
      barBgClasses =
        "[&::-moz-progress-bar]:bg-new-warning60 [&::-webkit-progress-value]:bg-new-warning60";
    }
    if (testedResult?.score >= 4) {
      barBgClasses =
        "[&::-moz-progress-bar]:bg-new-success60 [&::-webkit-progress-value]:bg-new-success60";
    }
  }

  return (
    <div>
      <progress
        className={cn(
          "w-full [&::-webkit-progress-bar]:rounded-lg [&::-webkit-progress-bar]:bg-new-neutral20 [&::-webkit-progress-value]:rounded-lg",
          barBgClasses,
        )}
        max="4"
        value={testedResult?.score}
      />
    </div>
  );
};
