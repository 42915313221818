import { useQuery } from "@apollo/client";
import { Role } from "apollo/types/graphql";
import { GET_USER } from "graphql/queries";
import { useEffect } from "react";

export type UserPermissions = {
  isContestAdmin: boolean;
  isContestAdminOf: (contestId: string | undefined | null) => boolean;
};

export const useUserData = () => {
  const { data } = useQuery(GET_USER);
  return data?.user;
};

export const useUserPermissions = (): UserPermissions => {
  const data = useUserData();
  const roles = data?.roles ?? [];
  const permissions: UserPermissions = {
    isContestAdminOf: (contestId: string | undefined | null) => {
      if (!contestId) {
        return false;
      }

      return !!roles.find(
        (role) =>
          role.role === Role.ContestAdmin && role.contest?.id === contestId,
      );
    },
    isContestAdmin: false,
  };
  for (const role of roles) {
    switch (role.role) {
      case Role.ContestAdmin:
        permissions.isContestAdmin = true;
        break;
    }
  }
  return permissions;
};

export const useInitUserQuery = () => {
  const { data, error, loading } = useQuery(GET_USER, {
    errorPolicy: "none",
  });
  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  return { data, loading };
};
