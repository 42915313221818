import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import {
  ButtonVariant,
  PrimaryButton,
  SecondaryButton,
} from "components/Button";
import { t } from "i18n";
import {
  PropsWithChildren,
  ReactElement,
  createContext,
  useState,
} from "react";
import { AlertCircle } from "react-feather";

export type WarningModalContextType = {
  closeWarningModal: () => void;
  isWarningModalOpen: boolean;
  openWarningModal: (props: OpenWarningModalProps) => void | Promise<void>;
};
export const WarningModalContext = createContext<WarningModalContextType>({
  isWarningModalOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openWarningModal: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeWarningModal: () => {},
});

type WarningModalProps =
  | PropsWithChildren
  | {
      children: (props: WarningModalContextType) => ReactElement;
    };

type OpenWarningModalProps = {
  confirmButtonText?: string;
  description: string;
  heading?: string;
  onConfirm: () => void;
};

export const WarningModalProvider = ({
  children,
}: WarningModalProps): ReactElement => {
  const [isWarningModalOpen, setModalOpen] = useState(false);
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [afterConfirmHeading, setAfterConfirmHeading] = useState("");
  const [afterConfirmDescription, setAfterConfirmDescription] = useState("");
  const [confirmButtonText, setConfirmButtonText] = useState("");
  const [confirmationFn, setConfirmationFn] = useState<
    () => void | Promise<void>
  >(
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    () => {},
  );

  const closeWarningModal = () => setModalOpen(false);

  const openWarningModal = async (
    props: OpenWarningModalProps,
  ): Promise<void> => {
    const { confirmButtonText, description, heading, onConfirm } = props;

    setConfirmationFn(() => onConfirm);
    if (heading) setHeading(heading);
    setDescription(description);
    setAfterConfirmHeading(afterConfirmHeading);
    setAfterConfirmDescription(afterConfirmDescription);
    if (confirmButtonText) setConfirmButtonText(confirmButtonText);
    setModalOpen(true);
  };

  const confirmModal = async (): Promise<void> => {
    await confirmationFn();
    setHeading(afterConfirmHeading);
    setDescription(afterConfirmDescription);
    closeWarningModal();
  };

  const value: WarningModalContextType = {
    isWarningModalOpen,
    openWarningModal,
    closeWarningModal,
  };

  return (
    <WarningModalContext.Provider value={value}>
      <Modal
        isOpen={isWarningModalOpen}
        size="md"
        isCentered
        onClose={closeWarningModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody p={6}>
            <div className="mb-4 leading-none">
              <div className="inline-block rounded-full border-8 border-error50 bg-error100 p-2 text-error600">
                <AlertCircle size={20} />
              </div>
            </div>
            <h3 className="mb-2 text-lg font-medium text-gray900">
              {heading || t.common.areYouSure}
            </h3>
            <p className="text-sm text-gray500">{description}</p>
            <div className="mt-8 flex flex-row gap-3">
              <SecondaryButton className="grow" onClick={closeWarningModal}>
                {t.common.cancel}
              </SecondaryButton>
              <PrimaryButton
                className="grow"
                variant={ButtonVariant.Danger}
                onClick={confirmModal}
              >
                {confirmButtonText || t.common.remove}
              </PrimaryButton>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      {typeof children === "function" ? children(value) : children}
    </WarningModalContext.Provider>
  );
};
