import { useMutation } from "@apollo/client";
import { SIGN_OUT } from "graphql/mutations";
import { useAuth, useAuthDispatch } from "root/AuthProvider";

import { AuthLocationState } from "./authLocationState";

export function useSignOut() {
  const tokens = useAuth();
  const setAuth = useAuthDispatch();
  const [signOut] = useMutation(SIGN_OUT);

  return async () => {
    if (tokens == null) return;
    try {
      await signOut();
    } catch (err) {
      console.warn(err);
    } finally {
      setAuth(null);
    }
  };
}

export type GoogleOAuthState = {
  action: "signin" | "signup";
  authLocationState: AuthLocationState;
};

function stringifyOAuthState(state: GoogleOAuthState): string {
  try {
    return JSON.stringify(state);
  } catch (err) {
    console.error(err);
    return JSON.stringify({ action: state.action });
  }
}

export function parseOAuthState(
  state: string | null,
): GoogleOAuthState | Error {
  if (typeof state !== "string") {
    return new TypeError("Expected string");
  }
  try {
    const parsed = JSON.parse(state);
    const hasAction = typeof parsed.action === "string";
    const hasAuthLocationState =
      typeof parsed.authLocationState === "object" &&
      parsed.authLocationState != null;
    if (!hasAction) {
      return new Error("State object missing auth action type");
    }
    if (hasAuthLocationState) {
      return parsed;
    } else {
      return {
        action: parsed.action,
        authLocationState: {},
      };
    }
  } catch (err) {
    if (err instanceof Error) {
      return err;
    } else {
      return new Error("Unknown error");
    }
  }
}

export function buildGoogleOAuth2URL(state: GoogleOAuthState): string {
  const url = new URL("https://accounts.google.com");
  url.pathname = "o/oauth2/v2/auth";
  url.searchParams.set("state", stringifyOAuthState(state));
  url.searchParams.set("scope", process.env.GOOGLE_SCOPE ?? "");
  url.searchParams.set("redirect_uri", process.env.GOOGLE_REDIRECT_URI ?? "");
  url.searchParams.set("client_id", process.env.GOOGLE_CLIENT_ID ?? "");
  url.searchParams.set("access_type", "offline");
  url.searchParams.set("response_type", "code");
  url.searchParams.set("include_granted_scopes", "true");
  return url.toString();
}
