import ROUTES, { AppRoute } from "const/routes";
import { useNavigate, useSearchParams } from "react-router-dom";

export type AuthRedirectFn = () => void;

export type AuthRedirect = {
  redirect: AuthRedirectFn;
};

export function useAuthRedirect(): AuthRedirect {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  function getRoute(): AppRoute {
    if (searchParams.get("route") === "sign_in") {
      return ROUTES.SIGN_IN;
    } else if (searchParams.get("route") === "sign_up") {
      return ROUTES.SIGN_UP;
    } else {
      return ROUTES.SIGN_IN;
    }
  }

  const redirect: AuthRedirectFn = () => {
    navigate(getRoute(), { replace: true });
  };

  return {
    redirect,
  };
}
