import * as React from "react";
import { Eye, EyeOff } from "react-feather";
import { parameterize } from "utils/string";
import { cn } from "utils/tailwind-merge";

import { Label } from "../Label";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  label?: string;
  leftIcon?: JSX.Element;
  name?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, error, label, leftIcon, name, type, ...props }, ref) => {
    name = parameterize(label || name || "");
    const [showPassword, setShowPassword] = React.useState(false);

    return (
      <div>
        {label && <Label htmlFor={name}>{label}</Label>}
        <div className="relative flex items-center">
          {leftIcon && (
            <div className="absolute left-3 text-new-neutral60">{leftIcon}</div>
          )}
          <input
            className={cn(
              "from-new-neutral-neutral30 font-md mt-1 w-full rounded-lg border pb-2 pl-3 pr-3 pt-2 text-new-neutral60 disabled:cursor-not-allowed disabled:opacity-50",
              error
                ? "border-new-danger40 focus:outline-new-danger40 "
                : "border-new-neutral30 focus:outline-new-neutral90",
              leftIcon && "pl-12",
              className,
            )}
            id={name}
            name={label}
            ref={ref}
            type={type === "password" && showPassword ? "text" : type}
            {...props}
          />
          {type === "password" && (
            <div
              className="absolute bottom-3 left-3 text-new-neutral50 hover:cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
            >
              {!showPassword ? <EyeOff size={16} /> : <Eye size={16} />}
            </div>
          )}
        </div>
      </div>
    );
  },
);
Input.displayName = "Input";

export { Input };
