/* eslint-disable */
import * as types from "./graphql";
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  "\n  mutation VerifyEmail($verificationId: String!) {\n    verifyEmail(verificationId: $verificationId) {\n      email\n    }\n  }\n":
    types.VerifyEmailDocument,
  "\n  mutation ResendVerificationEmail {\n    resendVerificationEmail\n  }\n":
    types.ResendVerificationEmailDocument,
  "\nmutation DeleteCurrentUserSession {\n  deleteCurrentUserSession\n}\n":
    types.DeleteCurrentUserSessionDocument,
  "\n  query GetUser {\n    user {\n      id\n      email\n      name\n      church\n      dateOfBirth\n      roles {\n        role\n        contest {\n          id\n        }\n      }\n    }\n  }\n":
    types.GetUserDocument,
  "\nquery ContestAdmin($contestId: String!) {\n  contest(contestId: $contestId) {\n    contest {\n      id\n      title\n      description\n      imageUrl\n      quizzes {\n        id\n        title\n        description\n        isPublished\n      }\n    }\n  }\n}\n":
    types.ContestAdminDocument,
  "\nquery AdminContests {\n  adminContests {\n    id\n    title\n    description\n    imageUrl\n  }\n}\n":
    types.AdminContestsDocument,
  "\nquery Contest($contestId: String!) {\n  contest(contestId: $contestId) {\n    contest {\n      id\n      title\n      description\n      imageUrl\n      quizzes {\n        id\n        title\n        description\n        solved\n        isPublished\n      }\n    }\n    certificate\n  }\n}\n":
    types.ContestDocument,
  "\nquery QuizWithPoints($quizId: String!) {\n  quiz(quizId: $quizId) {\n    id\n    title\n    description\n    contest {\n      id\n      title\n    }\n    questions {\n      id\n      text\n      choices {\n        id\n        text\n        points\n      }\n    }\n  }\n}\n":
    types.QuizWithPointsDocument,
  "\nmutation EditQuiz($quizId: String!, $questions: [QuestionInput!]!) {\n  editQuiz(quizId: $quizId, questions: $questions) {\n    id\n  }\n}\n":
    types.EditQuizDocument,
  "\n  mutation OldRequestPasswordReset($email: String!) {\n    requestPasswordReset(email: $email)\n  }\n":
    types.OldRequestPasswordResetDocument,
  "\n  mutation LoginUserWithGoogle($token: String!) {\n    loginUserWithGoogleAuthToken(token: $token) {\n      accessToken\n      refreshToken\n    }\n  }\n":
    types.LoginUserWithGoogleDocument,
  "\n  mutation RegisterUserWithGoogle($token: String!) {\n    registerUserWithGoogleAuthToken(token: $token) {\n      accessToken\n      refreshToken\n    }\n  }\n":
    types.RegisterUserWithGoogleDocument,
  "\nquery Contests {\n  contests {\n    id\n    title\n    description\n    imageUrl\n    status\n  }\n}\n":
    types.ContestsDocument,
  "\nquery Quiz($quizId: String!) {\n  quiz(quizId: $quizId) {\n    id\n    title\n    solved\n    contest {\n      id\n      title\n    }\n    questions {\n      choices {\n        id\n        text\n      }\n      text\n      id\n    }\n  }\n}\n":
    types.QuizDocument,
  "\nmutation SubmitQuiz($quizId: String!, $answers: [String!]!) {\n  submitQuiz(quizId: $quizId, answers: $answers) {\n    name\n    choices {\n      id\n      text\n    }\n  }\n}\n":
    types.SubmitQuizDocument,
  "\nmutation OldResetPassword($requestId: String!, $newPassword: String!) {\n  resetPassword(requestId: $requestId, newPassword: $newPassword) {\n    accessToken\n    refreshToken\n  }\n}\n":
    types.OldResetPasswordDocument,
  "\nmutation RequestPasswordReset($email: String!) {\n  requestPasswordReset(email: $email)\n}\n":
    types.RequestPasswordResetDocument,
  "\nmutation ResetPassword($requestId: String!, $newPassword: String!) {\n  resetPassword(requestId: $requestId, newPassword: $newPassword) {\n    accessToken\n    refreshToken\n  }\n}\n":
    types.ResetPasswordDocument,
  "\nmutation LoginUser($email: String!, $password: String!) {\n  loginUser(email: $email, password: $password) {\n    accessToken\n    refreshToken\n  }\n}\n":
    types.LoginUserDocument,
  "\nmutation RegisterUser($email: String!, $password: String!, $church: String, $name: String!, $dateOfBirth: Date) {\n  registerUser(email: $email, password: $password, church: $church, name: $name, dateOfBirth: $dateOfBirth) {\n    accessToken\n    refreshToken\n  }\n}\n":
    types.RegisterUserDocument,
  "\nmutation UpdateProfileDetails($church: String, $name: String!, $dateOfBirth: Date) {\n  updateProfileDetails(church: $church, name: $name, dateOfBirth: $dateOfBirth) {\n    name\n    church\n    dateOfBirth\n  }\n}\n":
    types.UpdateProfileDetailsDocument,
  "\n  mutation CreateNewTokens($refreshToken: String!) {\n    createNewTokens(refreshToken: $refreshToken) {\n      accessToken\n      refreshToken\n    }\n  }\n":
    types.CreateNewTokensDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  mutation VerifyEmail($verificationId: String!) {\n    verifyEmail(verificationId: $verificationId) {\n      email\n    }\n  }\n",
): (typeof documents)["\n  mutation VerifyEmail($verificationId: String!) {\n    verifyEmail(verificationId: $verificationId) {\n      email\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  mutation ResendVerificationEmail {\n    resendVerificationEmail\n  }\n",
): (typeof documents)["\n  mutation ResendVerificationEmail {\n    resendVerificationEmail\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\nmutation DeleteCurrentUserSession {\n  deleteCurrentUserSession\n}\n",
): (typeof documents)["\nmutation DeleteCurrentUserSession {\n  deleteCurrentUserSession\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  query GetUser {\n    user {\n      id\n      email\n      name\n      church\n      dateOfBirth\n      roles {\n        role\n        contest {\n          id\n        }\n      }\n    }\n  }\n",
): (typeof documents)["\n  query GetUser {\n    user {\n      id\n      email\n      name\n      church\n      dateOfBirth\n      roles {\n        role\n        contest {\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\nquery ContestAdmin($contestId: String!) {\n  contest(contestId: $contestId) {\n    contest {\n      id\n      title\n      description\n      imageUrl\n      quizzes {\n        id\n        title\n        description\n        isPublished\n      }\n    }\n  }\n}\n",
): (typeof documents)["\nquery ContestAdmin($contestId: String!) {\n  contest(contestId: $contestId) {\n    contest {\n      id\n      title\n      description\n      imageUrl\n      quizzes {\n        id\n        title\n        description\n        isPublished\n      }\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\nquery AdminContests {\n  adminContests {\n    id\n    title\n    description\n    imageUrl\n  }\n}\n",
): (typeof documents)["\nquery AdminContests {\n  adminContests {\n    id\n    title\n    description\n    imageUrl\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\nquery Contest($contestId: String!) {\n  contest(contestId: $contestId) {\n    contest {\n      id\n      title\n      description\n      imageUrl\n      quizzes {\n        id\n        title\n        description\n        solved\n        isPublished\n      }\n    }\n    certificate\n  }\n}\n",
): (typeof documents)["\nquery Contest($contestId: String!) {\n  contest(contestId: $contestId) {\n    contest {\n      id\n      title\n      description\n      imageUrl\n      quizzes {\n        id\n        title\n        description\n        solved\n        isPublished\n      }\n    }\n    certificate\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\nquery QuizWithPoints($quizId: String!) {\n  quiz(quizId: $quizId) {\n    id\n    title\n    description\n    contest {\n      id\n      title\n    }\n    questions {\n      id\n      text\n      choices {\n        id\n        text\n        points\n      }\n    }\n  }\n}\n",
): (typeof documents)["\nquery QuizWithPoints($quizId: String!) {\n  quiz(quizId: $quizId) {\n    id\n    title\n    description\n    contest {\n      id\n      title\n    }\n    questions {\n      id\n      text\n      choices {\n        id\n        text\n        points\n      }\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\nmutation EditQuiz($quizId: String!, $questions: [QuestionInput!]!) {\n  editQuiz(quizId: $quizId, questions: $questions) {\n    id\n  }\n}\n",
): (typeof documents)["\nmutation EditQuiz($quizId: String!, $questions: [QuestionInput!]!) {\n  editQuiz(quizId: $quizId, questions: $questions) {\n    id\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  mutation OldRequestPasswordReset($email: String!) {\n    requestPasswordReset(email: $email)\n  }\n",
): (typeof documents)["\n  mutation OldRequestPasswordReset($email: String!) {\n    requestPasswordReset(email: $email)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  mutation LoginUserWithGoogle($token: String!) {\n    loginUserWithGoogleAuthToken(token: $token) {\n      accessToken\n      refreshToken\n    }\n  }\n",
): (typeof documents)["\n  mutation LoginUserWithGoogle($token: String!) {\n    loginUserWithGoogleAuthToken(token: $token) {\n      accessToken\n      refreshToken\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  mutation RegisterUserWithGoogle($token: String!) {\n    registerUserWithGoogleAuthToken(token: $token) {\n      accessToken\n      refreshToken\n    }\n  }\n",
): (typeof documents)["\n  mutation RegisterUserWithGoogle($token: String!) {\n    registerUserWithGoogleAuthToken(token: $token) {\n      accessToken\n      refreshToken\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\nquery Contests {\n  contests {\n    id\n    title\n    description\n    imageUrl\n    status\n  }\n}\n",
): (typeof documents)["\nquery Contests {\n  contests {\n    id\n    title\n    description\n    imageUrl\n    status\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\nquery Quiz($quizId: String!) {\n  quiz(quizId: $quizId) {\n    id\n    title\n    solved\n    contest {\n      id\n      title\n    }\n    questions {\n      choices {\n        id\n        text\n      }\n      text\n      id\n    }\n  }\n}\n",
): (typeof documents)["\nquery Quiz($quizId: String!) {\n  quiz(quizId: $quizId) {\n    id\n    title\n    solved\n    contest {\n      id\n      title\n    }\n    questions {\n      choices {\n        id\n        text\n      }\n      text\n      id\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\nmutation SubmitQuiz($quizId: String!, $answers: [String!]!) {\n  submitQuiz(quizId: $quizId, answers: $answers) {\n    name\n    choices {\n      id\n      text\n    }\n  }\n}\n",
): (typeof documents)["\nmutation SubmitQuiz($quizId: String!, $answers: [String!]!) {\n  submitQuiz(quizId: $quizId, answers: $answers) {\n    name\n    choices {\n      id\n      text\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\nmutation OldResetPassword($requestId: String!, $newPassword: String!) {\n  resetPassword(requestId: $requestId, newPassword: $newPassword) {\n    accessToken\n    refreshToken\n  }\n}\n",
): (typeof documents)["\nmutation OldResetPassword($requestId: String!, $newPassword: String!) {\n  resetPassword(requestId: $requestId, newPassword: $newPassword) {\n    accessToken\n    refreshToken\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\nmutation RequestPasswordReset($email: String!) {\n  requestPasswordReset(email: $email)\n}\n",
): (typeof documents)["\nmutation RequestPasswordReset($email: String!) {\n  requestPasswordReset(email: $email)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\nmutation ResetPassword($requestId: String!, $newPassword: String!) {\n  resetPassword(requestId: $requestId, newPassword: $newPassword) {\n    accessToken\n    refreshToken\n  }\n}\n",
): (typeof documents)["\nmutation ResetPassword($requestId: String!, $newPassword: String!) {\n  resetPassword(requestId: $requestId, newPassword: $newPassword) {\n    accessToken\n    refreshToken\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\nmutation LoginUser($email: String!, $password: String!) {\n  loginUser(email: $email, password: $password) {\n    accessToken\n    refreshToken\n  }\n}\n",
): (typeof documents)["\nmutation LoginUser($email: String!, $password: String!) {\n  loginUser(email: $email, password: $password) {\n    accessToken\n    refreshToken\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\nmutation RegisterUser($email: String!, $password: String!, $church: String, $name: String!, $dateOfBirth: Date) {\n  registerUser(email: $email, password: $password, church: $church, name: $name, dateOfBirth: $dateOfBirth) {\n    accessToken\n    refreshToken\n  }\n}\n",
): (typeof documents)["\nmutation RegisterUser($email: String!, $password: String!, $church: String, $name: String!, $dateOfBirth: Date) {\n  registerUser(email: $email, password: $password, church: $church, name: $name, dateOfBirth: $dateOfBirth) {\n    accessToken\n    refreshToken\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\nmutation UpdateProfileDetails($church: String, $name: String!, $dateOfBirth: Date) {\n  updateProfileDetails(church: $church, name: $name, dateOfBirth: $dateOfBirth) {\n    name\n    church\n    dateOfBirth\n  }\n}\n",
): (typeof documents)["\nmutation UpdateProfileDetails($church: String, $name: String!, $dateOfBirth: Date) {\n  updateProfileDetails(church: $church, name: $name, dateOfBirth: $dateOfBirth) {\n    name\n    church\n    dateOfBirth\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  mutation CreateNewTokens($refreshToken: String!) {\n    createNewTokens(refreshToken: $refreshToken) {\n      accessToken\n      refreshToken\n    }\n  }\n",
): (typeof documents)["\n  mutation CreateNewTokens($refreshToken: String!) {\n    createNewTokens(refreshToken: $refreshToken) {\n      accessToken\n      refreshToken\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
