import { QuizParams } from "const/routes";
import { useParams } from "react-router-dom";

import { useQuiz } from "./hooks";

const Heading = () => {
  const { quizId } = useParams<QuizParams>() as QuizParams;

  const { data } = useQuiz(quizId);

  return (
    <div className="flex w-full flex-col items-center">
      <h1 className="mt-5 text-center text-xl">{data?.quiz.contest.title}</h1>
      <h1 className="mt-2 text-center text-header font-bold">
        {data?.quiz.title}
      </h1>

      <div className="mt-3 h-0.5 w-full bg-gray-100" />
    </div>
  );
};

export default Heading;
