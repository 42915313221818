import {
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import ROUTES from "const/routes";
import { useUserData } from "graphql/user";
import React from "react";
import { User } from "react-feather";
import { Link, Outlet } from "react-router-dom";
import { useSignOut } from "utils/auth";

type NavigationBarProps = {
  withIcon?: boolean;
};

const NavigationBar: React.FC<NavigationBarProps> = ({ withIcon = true }) => {
  const user = useUserData();
  const signOut = useSignOut();

  return (
    <div className="flex flex-col overflow-y-hidden">
      <header className="App-header">
        <div className="m-auto block flex max-w-5xl flex-row justify-between">
          {withIcon ? (
            <Link to={ROUTES.HOME}>
              <img
                alt="landing"
                className="m-2 h-10 w-10 rounded-full"
                src="/favicon.png"
              />
            </Link>
          ) : (
            <div />
          )}
          {user && (
            <Menu>
              <MenuButton
                aria-label="Options"
                as={IconButton}
                className="ml-1 mt-1"
                icon={
                  <User className="h-8 w-8 rounded-full outline outline-gray800" />
                }
                variant="ghost"
              ></MenuButton>
              <MenuList className="ml-1" minWidth={64}>
                <div className="flex flex-col px-4">
                  <h1>{user.name}</h1>
                  <h1>{user.email}</h1>
                </div>
                <MenuDivider />
                <MenuItem as={Link} to={ROUTES.PROFILE}>
                  الحساب
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={signOut}>
                  <h1>تسجيل الخروج</h1>
                </MenuItem>
              </MenuList>
            </Menu>
          )}
          {/* {!user && (
            <Link to={ROUTES.SIGN_IN}>
              <Button btnStyle="transparent">تسجيل الدخول</Button>
            </Link>
          )} */}
        </div>
      </header>
      <Outlet />
    </div>
  );
};

export default NavigationBar;
