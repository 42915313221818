import { ar } from "date-fns/locale";
import * as React from "react";
import { DayPicker } from "react-day-picker";
import { ChevronLeft, ChevronRight } from "react-feather";
import { cn } from "utils/tailwind-merge";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      className={cn("p-3 text-new-neutral70", className)}
      classNames={{
        months: "flex space-x-4",
        month: "space-y-4",
        caption: "flex justify-center pt-1 relative items-center px-10",
        caption_label: "flex items-center gap-2 text-md text-new-neutral70",
        caption_dropdowns: "flex gap-4 [&_.rdp-vhidden]:hidden",
        dropdown_month: "relative inline-flex items-center",
        dropdown_year: "relative inline-flex items-center",
        dropdown:
          "absolute inset-0 w-full appearance-none opacity-0 z-10 cursor-pointer",
        nav: "space-x-1 flex items-center justify-center",
        nav_button:
          "border border-new-neutral30 bg-new-neutral0 hover:bg-new-neutral30 text-new-neutral70 rounded-full h-7 w-7 p-0 flex justify-center items-center",
        nav_button_previous: "absolute left-1",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse space-y-1",
        head_row: "flex",
        head_cell: "text-new-neutral70 rounded-full w-9 font-medium text-sm",
        row: "flex w-full mt-2",
        cell: "h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-start)]:rounded-l-full [&:has([aria-selected].day-range-end)]:rounded-r-full [&:has([aria-selected])]:bg-new-neutral10 first:[&:has([aria-selected])]:rounded-l-full last:[&:has([aria-selected])]:rounded-r-full focus-within:relative focus-within:z-20",
        day: "inline-flex items-center justify-center whitespace-nowrap rounded-full text-sm ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-new-neutral20 h-9 w-9 p-0 aria-selected:font-medium",
        day_range_start: "day-range-start",
        day_range_end: "day-range-end",
        day_selected:
          "bg-new-neutral60 text-new-neutral0 hover:!bg-new-neutral60 hover:text-new-neutral0 focus:bg-new-neutral60 focus:text-new-neutarl0",
        day_today: "bg-new-neutral30 font-medium",
        day_disabled: "text-new-neutral50 opacity-50",
        day_range_middle:
          "aria-selected:bg-new-neutral10 aria-selected:text-new-neutral70",
        day_hidden: "invisible",
        ...classNames,
      }}
      components={{
        IconLeft: () => <ChevronLeft className="h-4 w-4" />,
        IconRight: () => <ChevronRight className="h-4 w-4" />,
      }}
      locale={ar}
      showOutsideDays={showOutsideDays}
      {...props}
    />
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
