import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { PrimaryButton } from "components/Button";
import { t } from "i18n";
import {
  PropsWithChildren,
  ReactElement,
  createContext,
  useState,
} from "react";
import { CheckCircle } from "react-feather";

export type SuccessModalContextType = {
  isSuccessModalOpen: boolean;
  openSuccessModal: (props: OpenSuccessModalProps) => void | Promise<void>;
};
export const SuccessModalContext = createContext<SuccessModalContextType>({
  isSuccessModalOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openSuccessModal: () => {},
});

type SuccessModalProps =
  | PropsWithChildren
  | {
      children: (props: SuccessModalContextType) => ReactElement;
    };

type OpenSuccessModalProps = {
  confirmButtonText?: string;
  description: string;
  heading?: string;
  onClose?: () => void | Promise<void>;
};

export const SuccessModalProvider = ({
  children,
}: SuccessModalProps): ReactElement => {
  const [isSuccessModalOpen, setModalOpen] = useState(false);
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [onClose, setOnClose] = useState<() => void | Promise<void>>();
  const [confirmButtonText, setConfirmButtonText] = useState("");

  const closeSuccessModal = async () => {
    if (onClose) {
      await onClose();
    }
    setModalOpen(false);
  };

  const openSuccessModal = async (
    props: OpenSuccessModalProps,
  ): Promise<void> => {
    const { confirmButtonText, description, heading, onClose } = props;

    setOnClose(() => onClose);
    if (heading) setHeading(heading);
    setDescription(description);

    if (confirmButtonText) setConfirmButtonText(confirmButtonText);
    setModalOpen(true);
  };

  const value: SuccessModalContextType = {
    isSuccessModalOpen,
    openSuccessModal,
  };

  return (
    <SuccessModalContext.Provider value={value}>
      <Modal
        isOpen={isSuccessModalOpen}
        size="md"
        isCentered
        onClose={closeSuccessModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody p={6}>
            <div className="mb-4 leading-none">
              <div className="inline-block rounded-full border-8 border-primary50 bg-primary100 p-2 text-primary600">
                <CheckCircle size={20} />
              </div>
            </div>
            <h3 className="mb-2 text-lg font-medium text-gray900">{heading}</h3>
            <p className="text-sm text-gray500">{description}</p>
            <PrimaryButton className="mt-8 w-full" onClick={closeSuccessModal}>
              {confirmButtonText || t.common.gotItThanks}
            </PrimaryButton>
          </ModalBody>
        </ModalContent>
      </Modal>
      {typeof children === "function" ? children(value) : children}
    </SuccessModalContext.Provider>
  );
};
