import { Quiz } from "apollo/types/graphql";
import { Button } from "components/new/Button";
import { ContestParams, quizRoute } from "const/routes";
import { t } from "i18n";
import { Link, useParams } from "react-router-dom";

import { useContest } from "./hooks";

const Quizzes = () => {
  const { contestId } = useParams<ContestParams>() as ContestParams;

  const { data, refetch } = useContest(contestId);
  const quizzes = data?.contest.contest.quizzes;

  const certificate = data?.contest.certificate;
  const areAllQuizzesSolved = quizzes?.every((quiz) => quiz.solved);
  let interval: NodeJS.Timeout | null = null;
  if (areAllQuizzesSolved && !certificate) {
    interval = setInterval(() => {
      refetch();
      if (interval) {
        clearInterval(interval);
      }
    }, 5000);
  }

  return (
    <div className="flex w-full flex-col items-center p-5">
      {quizzes?.map((quiz: Partial<Quiz>) => (
        <>
          <div className="mt-3 flex w-full flex-row items-center">
            <div className="flex h-10 w-10 items-center justify-center">
              {quiz.solved ? (
                <img
                  className="h-7 w-7"
                  src="https://static.vecteezy.com/system/resources/previews/011/577/724/original/3d-rendering-of-checkmark-icon-true-choice-png.png"
                />
              ) : quiz.isPublished ? (
                <div className="h-2.5 w-2.5 rounded-full bg-[#205FDA]" />
              ) : (
                <div className="h-2.5 w-2.5 rounded-full bg-gray-400" />
              )}
            </div>
            {quiz.isPublished ? (
              <h1 className="mr-2 grow text-right text-xl">{quiz.title}</h1>
            ) : (
              <h1 className="mr-2 text-right text-xl text-gray-400">
                {quiz.title}
              </h1>
            )}

            {quiz.isPublished && !quiz.solved && (
              <Link to={quizRoute({ quizId: quiz.id })}>
                <Button btnStyle="white">{t.contest.user.startQuiz}</Button>
              </Link>
            )}
          </div>

          <div className="mt-3 h-0.5 w-full bg-gray-100" />
        </>
      ))}
      {certificate && (
        <div className="mt-3 flex w-full flex-col items-center">
          <h1 className="mt-5 w-full text-right text-header">
            شكرا لمشاركتكم في المسابقة
          </h1>
          <a
            className="mt-3"
            href={certificate}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              alt="certificate"
              className="w-full"
              src={certificate}
              title="certificate"
            />
          </a>
        </div>
      )}
    </div>
  );
};

export default Quizzes;
