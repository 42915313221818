import { useQuery } from "@apollo/client";
import { gql } from "apollo/types";
import { Contest, ContestStatus } from "apollo/types/graphql";
import { contestRoute } from "const/routes";
import { t } from "i18n";
import { Link } from "react-router-dom";

const CONTESTS = gql(`
query Contests {
  contests {
    id
    title
    description
    imageUrl
    status
  }
}
`);

const Contests = () => {
  const { data } = useQuery(CONTESTS);
  const contests = data?.contests;
  const contestsByStatus = [
    {
      id: "active",
      title: t.home.currentContests,
      contests: contests?.filter(
        (contest: Partial<Contest>) => contest.status === ContestStatus.Active,
      ),
    },
    {
      id: "upcoming",
      title: t.home.upcomingContests,
      contests: contests?.filter(
        (contest: Partial<Contest>) =>
          contest.status === ContestStatus.Upcoming,
      ),
    },
    {
      id: "past",
      title: t.home.pastContests,
      contests: contests?.filter(
        (contest: Partial<Contest>) => contest.status === ContestStatus.Past,
      ),
    },
  ];

  return (
    <>
      {contestsByStatus.map(({ contests, id, title }) => (
        <div className="flex w-full flex-col items-center" key={id}>
          {contests && contests.length > 0 && (
            <>
              <h1 className="mt-5 text-xl font-medium">{title}</h1>
              {contests?.map((contest: Partial<Contest>) => (
                <Link
                  className="relative w-11/12"
                  key={contest.id}
                  to={contestRoute({ contestId: contest.id })}
                >
                  <div>
                    <img
                      alt="landing"
                      className="h-50 mt-5 w-full rounded-lg object-cover"
                      src={contest.imageUrl}
                    />
                    <div className="absolute bottom-2 right-2 text-white">
                      <h1 className="text-bold text-right text-2xl">
                        {contest.title}
                      </h1>
                      <h1 className="text-right text-xl">
                        {contest.description}
                      </h1>
                    </div>
                  </div>
                </Link>
              ))}
            </>
          )}
        </div>
      ))}
    </>
  );
};

export default Contests;
