import Heading from "./Heading";
import Quizzes from "./Quizzes";

const ContestPage = () => {
  return (
    <div className="m-auto block flex max-w-4xl flex-col items-center">
      <Heading />
      <Quizzes />
    </div>
  );
};

export default ContestPage;
