import { gql } from "apollo/types";

export const GET_USER = gql(`
  query GetUser {
    user {
      id
      email
      name
      church
      dateOfBirth
      roles {
        role
        contest {
          id
        }
      }
    }
  }
`);
