import React from "react";

export type AuthState = {
  accessToken?: string | null;
  refreshToken?: string | null;
} | null;
const AuthContext = React.createContext<AuthState | null>(null);
export type AuthDispatch = (state: AuthState) => void;
const AuthDispatchContext = React.createContext<AuthDispatch | null>(null);

export function useAuth(): AuthState | null {
  return React.useContext(AuthContext);
}

export function useAuthDispatch(): AuthDispatch {
  const ctx = React.useContext(AuthDispatchContext);
  if (ctx == null) {
    throw new Error(
      "useAuthDispatch hook cannot be used outside the AuthProvider",
    );
  } else {
    return ctx;
  }
}

const AUTH_STORAGE_KEY_REFRESH = "LogozRefreshToken";

function setCookie(key: string, value: string): void {
  const date = new Date();
  date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
  document.cookie =
    key + "=" + value + "; expires=" + date.toUTCString() + ";Secure; path=/";
}

function delCookie(key: string): void {
  const date = new Date();
  date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);
  document.cookie =
    key + "=; expires=" + date.toUTCString() + ";Secure; path=/";
}

function getCookie(key: string) {
  const value = "; " + document.cookie;
  const parts = value.split("; " + key + "=");
  if (parts.length == 2) {
    return parts.pop()?.split(";").shift();
  }
}

function persistSession(state: AuthState): void {
  try {
    if (state == null) {
      delCookie(AUTH_STORAGE_KEY_REFRESH);
    } else if (state.refreshToken) {
      setCookie(AUTH_STORAGE_KEY_REFRESH, state.refreshToken);
    }
  } catch (err) {
    console.warn(err);
  }
}

function retrieveAuth(): AuthState | null {
  const refreshToken = getCookie(AUTH_STORAGE_KEY_REFRESH);
  if (refreshToken) {
    return {
      refreshToken,
      accessToken: null,
    };
  }
  return null;
}

/**
 * Provides AuthState accessible through the `useAuth` hook and
 */
export const AuthProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [authState, setAuthState] = React.useState<AuthState>(retrieveAuth());

  const authDispatch: AuthDispatch = (state) => {
    setAuthState(state);
    persistSession(state);
  };

  return (
    <AuthContext.Provider value={authState}>
      <AuthDispatchContext.Provider value={authDispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthContext.Provider>
  );
};
