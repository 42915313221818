import * as React from "react";
import { cn } from "utils/tailwind-merge";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: boolean;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, error, ...props }, ref) => {
    return (
      <textarea
        className={cn(
          "from-new-neutral-neutral30 font-md mt-1 flex min-h-[80px] w-full rounded-lg border bg-gradient-to-r to-white pb-2 pl-3 pr-3 pt-2 text-new-neutral60 placeholder:text-new-neutral50 disabled:cursor-not-allowed disabled:opacity-50",
          error
            ? "border-new-danger40 focus:outline-new-danger40 "
            : "border-new-neutral30 focus:outline-new-neutral90",
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Textarea.displayName = "Textarea";

export { Textarea };
