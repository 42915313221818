import Heading from "./Heading";
import Questions from "./Questions";

const EditQuizPage = () => {
  return (
    <div className="m-auto block flex max-w-4xl flex-col items-center pt-20">
      <Heading />
      <Questions />
    </div>
  );
};

export default EditQuizPage;
