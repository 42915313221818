/**
 * A more convenient way of joining classNames which might be empty
 */
export function classNames(
  ...names: (string | boolean | undefined | null)[]
): string {
  return names
    .flatMap((name) => (typeof name === "string" && name ? [name] : []))
    .join(" ");
}
