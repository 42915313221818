/**
 * Substitutes template variables in the form of `{var}` for actual values
 *
 * Is typechecked at runtime for non-production builds
 *
 * @example
 * expect(format("Hello, {name}!", {name: "World"})).toBe("Hello, World!");
 */
export function format(
  translation: string,
  params: Record<string, string | number>,
): string {
  if (process.env.NODE_ENV !== "production") {
    // Safe format, checks, throws if there is any mismatch between the template string and the provided parameters
    // Gets optimised away in a production build
    const matches = translation.matchAll(/\{\w+\}/g);
    const unique = [...new Set(Array.from(matches).map((m) => m[0]))];
    if (unique.length !== Object.keys(params).length) {
      throw new Error(
        `Mismatch between the number of variables in the provided i18n template and the number of provided parameters: expected ${
          unique.length
        }, got ${Object.keys(params).length} for string '${translation}'`,
      );
    }
    try {
      for (const match of unique) {
        const paramKey = match.substring(1, match.length - 1);
        const param = params[paramKey].toString();
        translation = translation.replaceAll(match, param);
      }
      return translation;
    } catch (err) {
      throw new Error(
        `A parameter required by the i18n template string either wasn't provided or was of an incorrect type for string '${translation}': ${JSON.stringify(
          params,
        )}`,
        { cause: err },
      );
    }
  } else {
    // Fast format, if a parameter is not provided, it stays in the result string
    return Object.keys(params).reduce(
      (s, key) => s.replaceAll(`{${key}}`, params[key].toString()),
      translation,
    );
  }
}
