import { useQuery } from "@apollo/client";
import { gql } from "apollo/types";

const CONTEST_ADMIN = gql(`
query ContestAdmin($contestId: String!) {
  contest(contestId: $contestId) {
    contest {
      id
      title
      description
      imageUrl
      quizzes {
        id
        title
        description
        isPublished
      }
    }
  }
}
`);

export const useContestAdmin = (contestId: string) => {
  const { data } = useQuery(CONTEST_ADMIN, {
    variables: { contestId },
  });

  return {
    data,
  };
};
